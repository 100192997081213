import {Component, OnInit, Input, OnChanges, Output, EventEmitter} from '@angular/core';

import {PagerService} from "./services/pager.service";


@Component({
    selector: 'pagination',
    templateUrl: 'pagination.html',
    styleUrls:['pagination.scss']
})

export class PaginationComponent implements OnInit, OnChanges {
    constructor(private pagerService: PagerService) { }

    @Input() count: number;
    @Input() pageSize: number = 10;
    @Input() currPage: number;

    @Output() currPageChange: EventEmitter<any> = new EventEmitter();

    // pager object
    pager: any = {};

    ngOnInit() {
        console.log('curr page pagination comp', this.currPage);
        this.setPage(this.currPage);
    }

    ngOnChanges() {
      // this.setPage(1);
      this.setPage(this.currPage);
      console.log('curr page pagination comp ngOnChanges', this.currPage);
        if(this.currPage == 1) {
            this.pager = this.pagerService.getPager(this.count, 1, this.pageSize);
        }
    }

    setPage(page) {
        if (page < 1 || page > this.pager.totalPages) return
        this.pager = this.pagerService.getPager(this.count, page, this.pageSize);
        this.currPage = page;
        this.currPageChange.emit(this.currPage);
    }

    getPager() {
      return this.pager;
    }
}
