/**
 * Created by akul on 18/1/17.
 */
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from "@angular/router";
import {Observable} from "rxjs";
import { Inject, Injectable } from "@angular/core";
import {DataService} from "./data.service";

@Injectable()
export class ServiceDisableGuard implements CanActivate {
  constructor(@Inject(DataService) public  _dataService: DataService, @Inject(Router)public router:Router){ }

  canActivate(route:ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean{
    if(this._dataService.getViewSetting()){
      return true;
    }
    return false;
  }
}
