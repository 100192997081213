// import './app.loader.ts';
import { Component, ViewEncapsulation, ViewContainerRef } from '@angular/core';
import { Routes } from '@angular/router';
import { GlobalState } from './global.state';
import { BaThemePreloader, BaThemeSpinner } from './theme/services';
import { layoutPaths } from './theme/theme.constants';
import { BaThemeConfig } from './theme/theme.config';
import { BaMenuService } from './theme';
// import { ComponentsHelper } from 'ngx-bootstrap';

import { MENU } from './app.menu';
import { AuthGuard } from "./pages/auth.guard";
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppSettings, Settings } from './app.settings';
/*
 * App Component
 * Top Level Component
 */
@Component({
  selector: 'app-root',
  encapsulation: ViewEncapsulation.None,
  // styles: [require('normalize.css'), require('./app.scss')],
  styleUrls: ['./app.component.scss'],
  template: `
    <main [ngClass]="{'menu-collapsed': isMenuCollapsed}" baThemeRun>
      <div class="additional-bg"></div>
      <router-outlet></router-outlet>
    </main>
  `
})

export class App {

  isMenuCollapsed: boolean = false;
  public settings: Settings;

  constructor(private _state: GlobalState,
              private _spinner: BaThemeSpinner,
              private _config: BaThemeConfig,
              private _menuService: BaMenuService,
              private viewContainerRef: ViewContainerRef,
              private deviceService: DeviceDetectorService,
              public _appSettings:AppSettings) {
                this.settings = this._appSettings.settings;
   // this._menuService.updateMenuByRoutes(<Routes>MENU);

    this._fixModals();

    this._loadImages();

    this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
      this.isMenuCollapsed = isCollapsed;
    });
  }

  ngOnInit(){
      const deviceInfo = this.deviceService.getDeviceInfo();
      const isMobile = this.deviceService.isMobile();
      const isTablet = this.deviceService.isTablet();
      const isDesktop = this.deviceService.isDesktop();

      this.settings.isDesktop = isMobile || isTablet ? false : true;
  }

  public ngAfterViewInit(): void {
    // hide spinner once all loaders are completed

    BaThemePreloader.load().then((values) => {
      // console.log("Hiding...");
      this._spinner.hide();
    });
    
  }

  private _loadImages(): void {
    // register some loaders
  }

  private _fixModals(): void {
    // ComponentsHelper.prototype.getRootViewContainerRef = function () {
    //   // https://github.com/angular/angular/issues/9293
    //   if (this.root) {
    //     return this.root;
    //   }
    //   var comps = this.applicationRef.components;
    //   if (!comps.length) {
    //     throw new Error("ApplicationRef instance not found");
    //   }
    //   try {
    //     /* one more ugly hack, read issue above for details */
    //     var rootComponent = this.applicationRef._rootComponents[0];
    //     this.root = rootComponent._component.viewContainerRef;
    //     return this.root;
    //   }
    //   catch (e) {
    //     throw new Error("ApplicationRef instance not found");
    //   }
    // };
  }
}
