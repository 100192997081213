/**
 * Created by akul on 18/1/17.
 */
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from "@angular/router";
import {Observable} from "rxjs";
import { Inject, Injectable } from "@angular/core";
import {DataService} from "./data.service";

@Injectable()
export class AccessableGuard implements CanActivate {
  constructor(@Inject(DataService) public  _dataService: DataService, @Inject(Router)public router:Router){ }

  canActivate(route:ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean{
    // console.log(route.routeConfig.path);
    if((route.routeConfig.path =='um' && this._dataService.getModulePermission(route.routeConfig.path.toUpperCase()) < 2) || !this._dataService.getModulePermission(route.routeConfig.path.toUpperCase()) && route.routeConfig.path != "dashboard"){

      this.router.navigate(['pages']);
      return false;
    }
    return true;
  }
}
