import {Component, ViewEncapsulation, ViewChild, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'ba-card',
  templateUrl: 'baCard.html',
  styleUrls:['baCard.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BaCard {
  @Input() title:String;
  @Input() baCardClass:String;
  @Input() currValuesObj:any[];
  @Input() dropdownSets:any[];
  cardType = '';

  @Output() currValuesObjChange = new EventEmitter();
  @Output() currValueChanged = new EventEmitter();

  onCurrValuesChange(i,e){
    this.currValueChanged.emit({value:e, index:i});
  }

}
