import {Component, ViewEncapsulation, Input, OnInit, OnChanges} from '@angular/core';


@Component({
  selector: 'hover-div',
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'hoverDiv.html',
  styleUrls:['hoverDiv.scss']
})
export class HoverDiv implements OnInit,OnChanges{
  @Input() description:string = "1234567 asdfghjkl";
  @Input() top:string;
  @Input() left:string;

  constructor(){

  }

  ngOnChanges(){

  }
  ngOnInit(){

  }
}
