import { NgModule, ModuleWithProviders }      from '@angular/core';
import { CommonModule }  from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { KpToggleDirectiveModule } from './directives/kp-toggle.directive';
// import {AutocompleteLibModule} from 'angular-ng-autocomplete';
 


import {
  BaThemeConfig
} from './theme.config';

import {
  BaThemeConfigProvider
} from './theme.configProvider';

import {
  BaBackTop,
  BaCard,
  BaMenuItem,
  BaMenu,
  BaMsgCenter,
  BaHeader,
  BaCartCount,
  BaUserProfile,
  PaginationComponent,
  BaSidebar,
  BaSideMenu,
  MenuItem,
  CustomPaginationComponent,
  StatusgraphComponent,
  MessageSupport
  // LineStatus
} from './components';

import { BaCardBlur } from './components/baCard/baCardBlur.directive';

import {
  BaScrollPosition,
  BaSlimScroll,
  BaThemeRun
} from './directives';

import {
  BaAppPicturePipe,
  BaKameleonPicturePipe,
  BaProfilePicturePipe
} from './pipes';

import {
  BaMenuService,
  BaHeaderService,
  BaThemePreloader,
  BaThemeSpinner,
  PagerService
} from './services';

import {
  EmailValidator,
  EqualPasswordsValidator,
} from './validators';
import {SmartTables} from "./components/smartTables/smartTables.component";
import {Ng2SmartTableModule} from "ng2-smart-table";
// import {Ng2SmartTableModule} from "./components/ng2-smart-table/build/src/ng2-smart-table.module";
import {HoverDiv} from "./components/hoverDiv/hoverDiv.component";
import { ConvertCurrencyService } from './services/convert-currency.service';
import { MmSearchComponent } from './components/baHeader/components/mm-search/mm-search.component';
import { CatalogueService } from '../pages/catalogue/catalogue.service';

const NGA_COMPONENTS = [
  BaBackTop,
  BaCard,
  BaMenuItem,
  MenuItem,
  BaMenu,
  BaMsgCenter,
  BaHeader,
  BaCartCount,
  BaUserProfile,
  PaginationComponent,
  BaSidebar,
  BaSideMenu,
  SmartTables,
  HoverDiv,
  CustomPaginationComponent,
  StatusgraphComponent,
  MessageSupport,
  MmSearchComponent
];

const NGA_DIRECTIVES = [
  BaScrollPosition,
  BaSlimScroll,
  BaThemeRun,
  BaCardBlur
];

const NGA_PIPES = [
  BaAppPicturePipe,
  BaKameleonPicturePipe,
  BaProfilePicturePipe
];

const NGA_SERVICES = [
  BaThemePreloader,
  BaThemeSpinner,
  BaHeaderService,
  BaMenuService,
  PagerService
];

const NGA_VALIDATORS = [
  EmailValidator,
  EqualPasswordsValidator,
];

@NgModule({
  declarations: [
    ...NGA_PIPES,
    ...NGA_DIRECTIVES,
    ...NGA_COMPONENTS,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2SmartTableModule,
    KpToggleDirectiveModule,
    // AutocompleteLibModule
  ],
  exports: [
    ...NGA_PIPES,
    ...NGA_DIRECTIVES,
    ...NGA_COMPONENTS
  ],
  providers: [
    ConvertCurrencyService
  ]
})

export class NgaModule {
  static forRoot(): ModuleWithProviders<NgaModule> {
    return {
      ngModule: NgaModule,
      providers: [
        BaThemeConfigProvider,
        BaThemeConfig,
        ...NGA_VALIDATORS,
        ...NGA_SERVICES,
        CatalogueService
      ],
    };
  }
}