import { Injectable } from "@angular/core";
@Injectable({providedIn: 'root'}) 
export class GlobalService {
  supplierBucket:any[] = [];
  rfqsMap:any = {};
  carousels: any[] = [];

  setSupplierBucket(supplierBucket:any[]){
    this.supplierBucket=supplierBucket;
  }


  getSupplierBucket():any[]{
    return this.supplierBucket;
  }

  setRfqsMap(_rfqsMap:any){
    this.rfqsMap = _rfqsMap;
  }

  getRfqsMap():any[]{
    return this.rfqsMap;
  }

}
