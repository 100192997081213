export const params = {
  version : '0.6.0',
  url_seperator : '/',
  APPLICATIONID : 1,
  URL_SEPERATOR : '/',
  SERVER : {
    "DOMAIN": "buyersqanew.moglix.com",
    "DEPLOYMENT_MODE": "test",

    "SAP_URL": "https://sapint.moglix.com/",

    "WS_URL":"wss://procurementpp.moglix.com/procurement/",
    "AUTH_URL" : "https://authlivepp.moglix.com/login/",
    "PROCUREMENT_URL" : "https://procurementpp.moglix.com/procurement/",
    "DATASYNC_URL": " https://datasyncpp.moglix.com/buyers/",
    "MOGLIX_API_URL": "https://api.moglix.com/",

    "IDENTITY":"moglix",
    "INVOICE_URL" : "https://invoice.moglilabs.com",
    "EMS_API": "https://ems.moglix.com/",

    "BUCKET_URL" : "stg.moglix.com/",
    "GST_SERVER_URL":"https://gstkb.greengst.com",
    "key" : "451964377672-h1vj251ckbdni1cdak9is7negqcl627k.apps.googleusercontent.com",
    "GSTNDETAILS_URL": "https://newproductionapi.greengst.com/public_api/getTaxpayerByGstin",
    "EOC_API":"https://eocapipp.moglix.com/",
    "UPDATE_PO": "http://emsqa.moglilabs.com/",
  },
  MODULES : {
    'USER_DASHBOARD' : 'User Dashboard',
    'PLACE_ORDER' : 'Place Order',
    'MANAGE_ORDER' : 'Manage Order',
    'MANAGE_PRODUCT' : 'Manage Product',
    'MANAGE_SUPPLIER' : 'Manage Supplier',
    'PROCUREMENT_DASHBOARD' : 'Procurement Dashboard',
    'GATE_ENTRY' : 'Gate Entry',
    'STORE_ENTRY' : 'Store Entry',
    'STORE_INVENTORY' : 'Store Inventory',
    'PR' : 'PR',
    'RFQ' : 'Rfq',
    'PURCHASE_ORDER' : 'Purchase Order',
    'PAYEMENTS' : 'Payements',
    'USER_MANAGEMENT' : 'User Management',
    'USER_DETAILS' : 'User Details',
    'GST' : 'GST'
  },
  ACCESS_LEVEL : {
    'READ' : 1,
    'WRITE' :2
  },
  PAGE_SIZE : {
    'PRODUCT' : 20,
    'ORDER' : 10,
    'SUPPLIER' : 20,
    'PAYMENT': 50
  },
  DIRECTORY : {
    'PRODUCT_UPLOAD' : '/var/moglilabs/business/product_upload/',
    'TEMPLATE' : '/var/moglilabs/business/template/',
    'SUPPLIER_UPLOAD' : '/var/moglilabs/business/supplier_upload/'
  },
  TEMPLATE_NAME : {
    'PRODUCT_UPLOAD' : 'product.csv',
    'SUPPLIER_UPLOAD' : 'supplier.csv'
  },
  ACTIVE_ITEM : {
    'Dummy' : 0,
    'New' : 1,
    'Processing' : 1,
    'Buyer Cancelled' : 0,
    'Vendor Cancelled' : 0,
    'Asn Generated' : 1,
    'Received' : 1,
    'Cust Rtrn Requested' : 0,
    'Cust Rtrn Approved' : 0,
    'Cust Rtrn Rejected' : 0,
    'Returned To Supplier' : 0,
    'Closed' : 1
  },
  SUPPLIER_TYPE : {
    '1' : 'Manufacturer',
    '2' : 'Distributor',
    '3' : 'Others'
  },
  ADDRESS_TYPES : {
    'BUSINESS' : 'Business Address',
    'SHIPPING' : 'Shipping Address',
    'INVOICE'  : 'Invoice Address'
  },
  ASSIST_TYPE : {
    '1' : 'ONLINE',
    '2' : 'ENTERPRISE'
  },
  ACTIONER : {
    'SUPPLIER' : 'Supplier',
    'BUYER' : 'Buyer'
  },
  MODULES_MAP:{
    "PR": 1,  //Purchase Requisition
    "RFQ": 2,  //Request For Quotation
    "PO": 3,
    "ASN": 4,  // Advance Shipping Note
    "GE": 5,  //Gate Entry
    "GRN": 6, //Goods Receipt Note
    "INV": 7, //Invoice
    "PMT": 8,  //Payments
    "SI": 9,   //Store Inventory
    "UM": 10,  //User Management
    "SM": 11,  //Vendor Management
    "PM": 12,  //Product Management
    "BI": 13,  //Business Insights
    "RA":14,   //Reverse Auction
    "MD":15,    //Master Data Edit
    "MC":16,    //Moglix Catalog
    "TC":17,    //TPL Catalog
    "SUP":18,   // Support
    "MM":19,     // MM Catalog
    "SA": 20,    // Scheduling Agreement
    "DOR": 21,
    "SUPP": 23,
    "POEXT": 24 
  },
  MODULES_NAME_MAP:{
    "PR": 'Purchase Requisition',
    "RFQ": 'Request For Quotation',
    "PO": 'Purchase Order',
    "ASN": 'Advance Shipping Note',
    "GE": 'Gate Entry',
    "GRN": 'Goods Receipt Note',
    "INV": 'Invoice',
    "PMT": 'Payments',
    "SI": 'Store Inventory',
    "UM": 'User Management',
    "SM": 'Vendor Management',
    "PM": 'Product Management',
    "BI": 'Business Insights',
    "RA": 'Reverse Auction'
  },
  monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ],
  days : ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
};

/*module.exports = {
 url, params
 }*/

// module.exports = params
