import { Component, ViewEncapsulation, Input, Output, EventEmitter, OnInit, AfterViewInit } from '@angular/core';
import { DataService } from '../../../../pages/data.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AppSettings, Settings } from '../../../../app.settings';
declare var $: any;
declare var dataLayer: any;

@Component({
  selector: 'menu-item',
  // encapsulation: ViewEncapsulation.None,
  templateUrl: 'menuItem.html',
  styleUrls: ['menuItem.scss']
})
export class MenuItem implements OnInit, AfterViewInit {

  @Input() menuItem: any;
  @Input() child: boolean = false;
  @Input() itemNumber: number;

  @Output() itemHover = new EventEmitter<any>();
  @Output() toggleSubMenu = new EventEmitter<any>();

  companyId: any;

  public settings: Settings;

  constructor(public _dataService: DataService, public _appSettings:AppSettings) {
    this.settings = this._appSettings.settings;
  }

  ngOnInit() {
    this.companyId = this._dataService.getBuyerCompanyId();
  }

  public onHoverItem($event): void {
    // this.itemHover.emit($event);
  }

  public onToggleSubMenu($event, item): boolean {
    $event.item = item;
    this.toggleSubMenu.emit($event);
    return false;
    //console.log('eveevevev',$event)
  }
  clickEnabled(item) {
    this._dataService.setSelectedModule(item.module);
    if(!this.settings.isDesktop){
      $('.mgb_sidebar').removeClass('active').addClass('inactive');
    }
  }

  shownext($event) {

  }
  ngAfterViewInit() {

  }

  opentTarget(link) {
    dataLayer.push({ 'event': 'eGiftVoucherClick' });
    window.open(link);
  }

}
