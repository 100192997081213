import { Injectable } from '@angular/core';
import { DataService } from './../data.service';
import { environment } from '../../../environments/environment';
import { CommonapiService } from '../commonapi.service';
import { Observable } from 'rxjs';

export const URLS_INFO = {
    FETCH_ALL_CATEGORIES: { METHOD: 'POST', URL: environment.URLS.SEARCH_URL + 'catalog/allCategories' },
    FETCH_CATEGORY_LIST: { METHOD: 'POST', URL: environment.URLS.SEARCH_URL + 'catalog/productsByCategory' },
    CREATE_RFQ: { METHOD: 'POST', URL: environment.URLS.EOC_API + 'po/rfqitem/create' },
    FETCH_PRODUCT_DETAILS: { METHOD: 'POST', URL: environment.URLS.SEARCH_URL + 'catalog/productDetail' },
    FETCH_BRANDS_LIST: { METHOD: 'POST', URL: environment.URLS.SEARCH_URL + 'catalog/allBrands' },
    FETCH_CATEGORY_LIST_BRANDS: { METHOD: 'POST', URL: environment.URLS.SEARCH_URL + 'catalog/productsByBrandAndCategory' },
    FETCH_SEARCH_LIST: { METHOD: 'POST', URL: environment.URLS.SEARCH_URL + 'catalog/searchProducts' },
    UPDATE_RFQ_CART_DETAILS: { METHOD: 'POST', URL: environment.URLS.PROCUREMENT_URL + 'cart/upsert/rfq' },
    FETCH_RFQ_CART_DETAILS: { METHOD: 'GET', URL: environment.URLS.EOC_API + 'cart/get/rfq' },
    DELETE_CART: { METHOD: 'DELETE', URL: environment.URLS.PROCUREMENT_URL + 'cart/empty/rfq' },
    REMOVE_VENDOR_ITEM: { METHOD: 'POST', URL: environment.URLS.PROCUREMENT_URL + 'cart/rfq/remove-item' }
}

@Injectable({
    providedIn:'root'
})
export class MmCatalogService {

    constructor(private dataService: DataService, private _commonapiService: CommonapiService) { }

    getAllCategories(request){
        return this.dataService.callRestful(URLS_INFO.FETCH_ALL_CATEGORIES.METHOD, URLS_INFO.FETCH_ALL_CATEGORIES.URL, request);
    }

    getCategoryList(filters){
        return this.dataService.callRestful(URLS_INFO.FETCH_CATEGORY_LIST.METHOD, URLS_INFO.FETCH_CATEGORY_LIST.URL, filters);
    }

    getCategoryListByBrands(filters){
        return this.dataService.callRestful(URLS_INFO.FETCH_CATEGORY_LIST_BRANDS.METHOD, URLS_INFO.FETCH_CATEGORY_LIST_BRANDS.URL, filters);
    }

    createRFQ(request){
        return this.dataService.callRestful(URLS_INFO.CREATE_RFQ.METHOD, URLS_INFO.CREATE_RFQ.URL, request);
    }

    getProducDetails(filters){
        return this.dataService.callRestful(URLS_INFO.FETCH_PRODUCT_DETAILS.METHOD, URLS_INFO.FETCH_PRODUCT_DETAILS.URL, filters);
    }

    getBrandsList(filters){
        return this.dataService.callRestful(URLS_INFO.FETCH_BRANDS_LIST.METHOD, URLS_INFO.FETCH_BRANDS_LIST.URL, filters);
    }

    getSimilarProducts(productName, categoryId) {
        let URL = environment.URLS.MOGLIX_API_URL + "search/similarproducts?str=" + productName + "&category=" + categoryId;
        return this.dataService.callRestful('GET', URL);
    }

    getListBySearchString(filters){
        return this.dataService.callRestful(URLS_INFO.FETCH_SEARCH_LIST.METHOD, URLS_INFO.FETCH_SEARCH_LIST.URL, filters);
    }

    getGiftCategoriesFirst(categories){
        let giftCategories = categories.filter(c => {
            return c['categoryDetails']['categoryName'].toLowerCase().includes("gift");
         });

         categories.forEach(cat => {
            let index = categories.indexOf(cat);
            if(cat['categoryDetails']['categoryName'].toLowerCase().includes("gift")){
               categories.splice(index, 1);
            }
         });

         if(giftCategories?.length){
            giftCategories.forEach(giftCat => {
                categories.unshift(giftCat);
            });
         }
    }
    
    searchSuggestions(filters){
        return this.dataService.callRestful('POST', environment.URLS.SEARCH_URL+'catalog/searchSuggestions', filters);
    }

    updateRfqCartDetails(request){
        return this.dataService.callRestful(URLS_INFO.UPDATE_RFQ_CART_DETAILS.METHOD, URLS_INFO.UPDATE_RFQ_CART_DETAILS.URL, request);
    }

    getRfqCartDetails()
    {
        return this.dataService.callRestful(URLS_INFO.FETCH_RFQ_CART_DETAILS.METHOD, URLS_INFO.FETCH_RFQ_CART_DETAILS.URL);
    }

    deleteCart()
    {
        return this.dataService.callRestful(URLS_INFO.DELETE_CART.METHOD, URLS_INFO.DELETE_CART.URL);
    }

    deleteVendorItem(request)
    {
        return this.dataService.callRestful(URLS_INFO.REMOVE_VENDOR_ITEM.METHOD, URLS_INFO.REMOVE_VENDOR_ITEM.URL, request);
    }

}
