import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { DataService } from './data.service';
import {urls} from "../pages/urls";
import { AppSettings, Settings } from '../app.settings';

export const URLS_INFO = {
  
  GET_CONFIG_BY_PAGE: { METHOD: 'POST', URL: environment.URLS.EOC_API + 'configuration/header/config/get'},
  // Common
  FETCH_HIERARCHY_USERS: { METHOD: 'POST', URL: environment.URLS.AUTH_URL + 'dataAccess/getUserDataAccessByCompany'},
// get all users lists
  FETCH_EXISTING_USERS: { METHOD: 'POST', URL: environment.URLS.AUTH_URL + 'user/getByCompany'},
}

@Injectable()

export class CommonapiService{

  private usersList = new BehaviorSubject<any>('');
  usersListData = this.usersList.asObservable();

  public settings: Settings;

  constructor(private dataService: DataService, public _appSettings:AppSettings) { 
    this.settings = this._appSettings.settings; 
  }

  getUrls(){
    let umFlag = localStorage.getItem('umFlag')
    return {
            GET_USERDETAILS:{ METHOD: 'POST', URL: `${environment.URLS.AUTH_URL}${umFlag === 'true' ? 'user/getNew' : 'user/get'}` },
            GET_APPROVER_NAME: { METHOD: 'POST', URL: `${environment.URLS.AUTH_URL}${umFlag === 'true' ? 'user/getNew' : 'user/get'}` },
            CREATE_PLANT: { METHOD: 'POST', URL: `${environment.URLS.AUTH_URL}${umFlag === 'true' ? 'branch/createNew' : 'branch/create'}` },
            GET_PLANT_DETAILS: { METHOD: 'POST', URL: `${environment.URLS.AUTH_URL}${umFlag === 'true' ? 'branch/getDetailsNew' : 'branch/getDetails'}` },
            FETCH_HSN_TAXRATES: { METHOD: 'POST', URL: `${environment.URLS.PROCUREMENT_URL}${umFlag === 'true' ? 'branch/getDetailsNew' : 'branch/getDetails'}` },
            FETCH_ALL_PLANTS: { METHOD: 'POST', URL: `${environment.URLS.AUTH_URL}${umFlag === 'true' ? 'branch/getAllByCompanyNew' : 'branch/getAllByCompany'}` },
            UPDATE_PLANT: { METHOD: 'POST', URL: `${environment.URLS.AUTH_URL}${umFlag === 'true' ? 'branch/updateDetailsNew' : 'branch/updateDetails'}` },
            FETCH_ALL_MODULES: { METHOD: 'POST', URL: `${environment.URLS.AUTH_URL}${umFlag === 'true' ? 'module/getAppModulesNew' : 'module/getAppModules'}` },
            UPDATE_MODULES: { METHOD: 'POST', URL: `${environment.URLS.AUTH_URL}${umFlag === 'true' ? 'companyModule/updateNew' : 'companyModule/update'}` },
            FETCH_COMPANY_MODULES: { METHOD: 'POST', URL: `${environment.URLS.AUTH_URL}${umFlag === 'true' ? 'companyModule/getAllNewModules' : 'companyModule/getAll'}` },
            UPDATE_ADDRESS: { METHOD: 'POST', URL: `${environment.URLS.AUTH_URL}${umFlag === 'true' ? 'address/createBranchAddMapNew' : 'address/createBranchAddMap'}` },
            GET_ROLES: { METHOD: 'POST', URL: `${environment.URLS.AUTH_URL}${umFlag === 'true' ? 'role/getCompanyHierarchyRoles' : 'role/getCompanyRoles'}` },
            FETCH_ALL_USERS: { METHOD: 'POST', URL: `${environment.URLS.AUTH_URL}${umFlag === 'true' ? 'user/getByBranchNew' : 'user/getByBranch'}` },
            FETCH_USERS_LIST: { METHOD: 'POST', URL: `${environment.URLS.AUTH_URL}${umFlag === 'true' ? 'user/getByBranchNew' : 'user/getByBranch'}` },
            FETCH_PLANT_DETAILS: { METHOD: 'POST', URL: `${environment.URLS.AUTH_URL}${umFlag === 'true' ? 'branch/getAddressDetailsNew' : 'branch/getAddressDetails'}` },
            FETCH_BRANCH_ACCESS: { METHOD: 'POST', URL: `${environment.URLS.AUTH_URL}${umFlag === 'true' ? 'user/getBranchAccessNew' : 'user/getBranchAccess'}` },
            GET_BRANCH_ACCESS: { METHOD: 'POST', URL: `${environment.URLS.AUTH_URL}${umFlag === 'true' ? 'user/getBranchAccessNew' : 'user/getBranchAccess'}` },
            UPDATE_ROLE_PERMISSIONS: { METHOD: 'POST', URL: `${environment.URLS.AUTH_URL}${umFlag === 'true' ? 'role/updateNewRole' : 'role/update'}` },
            CREATE_USER: { METHOD: 'POST', URL: `${environment.URLS.AUTH_URL}${umFlag === 'true' ? 'auth/registerNew' : 'auth/register'}` },
            FETCH_PAYTERMS_BUYERS_VENDOR: { METHOD: 'GET', URL: environment.URLS.PROCUREMENT_URL + 'terms/vendor/' },
            UM: {
              AUTH: {
                GET_BRANCH_ACCESS:`${umFlag === 'true' ? 'user/getBranchAccessNew' : 'user/getBranchAccess'}`,
              },
              COMPANY:{
                MODULE_PERMISSION:`${umFlag === 'true' ? 'companyModule/updateNew' : 'companyModule/update'}`,
                GET_MODULES:`${umFlag === 'true' ? 'companyModule/getAllNewModules' : 'companyModule/getAll'}`,
              },
              PLANTS:{
                GET:`${umFlag === 'true' ? 'branch/getAllByCompanyNew' : 'branch/getAllByCompany'}`,
                CREATE:`${umFlag === 'true' ? 'branch/createNew' : 'branch/create'}`,
                CREATE_MULTIPLE:`${umFlag === 'true' ? 'branch/createMultipleNew' : 'branch/createMultiple'}`,
                GET_PLANT:`${umFlag === 'true' ? 'branch/getDetailsNew' : 'branch/getDetails'}`,
                UPDATE:`${umFlag === 'true' ? 'branch/updateDetailsNew' : 'branch/updateDetails'}`,
                UPDATE_ADDRESS:`${umFlag === 'true' ? 'address/createBranchAddMapNew' : 'address/createBranchAddMap'}`,
                DELETE_ADDRESS:`${umFlag === 'true' ? 'address/deleteBranchAddMapNew' : 'address/deleteBranchAddMap'}`,
              },
              ROLES:{
                GET:`${umFlag === 'true' ? 'role/getCompanyHierarchyRoles' : 'role/getCompanyRoles'}`,
                CREATE:`${umFlag === 'true' ? 'role/createNewRole' : 'role/create'}`,
                UPDATE:`${umFlag === 'true' ? 'role/updateNewRole' : 'role/update'}`,
              },
              USERS:{ 
                GET: `${umFlag === 'true' ? 'user/getByBranchNew' : 'user/getByBranch'}`,
                GET_FOR_COMPANY: `${umFlag === 'true' ? 'user/getByCompanyNew' : 'user/getByCompany'}`,
                GET_USER:`${umFlag === 'true' ? 'user/getNew' : 'user/get'}`,
                CREATE:`${umFlag === 'true' ? 'auth/registerNew' : 'auth/register'}`,
              },
              MODULE:{
                GETALL:`${umFlag === 'true' ? 'module/getAppModulesNew' : 'module/getAppModules'}`,
              }
            }
    };
  }

  getConfigurations(request) {
    return this.dataService.callRestful(URLS_INFO.GET_CONFIG_BY_PAGE.METHOD, URLS_INFO.GET_CONFIG_BY_PAGE.URL, request);
  }

  getUserDataAccessByCompany(request) {
    return this.dataService.callRestful( URLS_INFO.FETCH_HIERARCHY_USERS.METHOD, URLS_INFO.FETCH_HIERARCHY_USERS.URL, request);
  }

  setUsersList(data) {
    this.usersList.next(data);
  }

  getAllUsersList(){
    let _reqObj = {
      idCompany: this.dataService.getBuyerCompanyId(),
      getCurrentUserUsers:true
    };
    
    return this.dataService.callRestful(URLS_INFO.FETCH_EXISTING_USERS.METHOD, URLS_INFO.FETCH_EXISTING_USERS.URL, _reqObj).subscribe((res: any) => {
      if (res['successful']) {
        this.setUsersList(res);
      }else{
        this.setUsersList('');
      }
    }, err => {
      this.setUsersList('');
    });
  }

  getUsersListByBranch(){
        this.settings.userListByBranch = [];
        const request = this.getUrls().FETCH_USERS_LIST.URL === 'user/getByBranchNew' ? 
        { 'idBranch': [this.dataService.getBuyerPlantId()] } : { 'idBranch': this.dataService.getBuyerPlantId() };
        return this.dataService.callRestful(this.getUrls().FETCH_USERS_LIST.METHOD, this.getUrls().FETCH_USERS_LIST.URL, request).subscribe((res: any) => {
          if (res.successful && res?.data && res?.data?.users?.length) {
              this.settings.userListByBranch = res.data.users;
          }
        });
    }

  updateSession(){
    let reqObj = {"dataType" : 1} ;
        this.dataService.callRestful('POST', environment.URLS.AUTH_URL+urls.AUTH.GET_SESSION, reqObj).subscribe(
          (data) => {
            if (data['successful']) {
              reqObj['dataType'] = 2;
              this.dataService.callRestful('POST', environment.URLS.AUTH_URL+urls.AUTH.GET_SESSION, reqObj).subscribe(
                  (sessionData) => {
                    if (sessionData['successful'] && sessionData['data']['companyData'] && Object.keys(sessionData['data']['companyData']['branchNames']).length > 0 ) {
                      let accessReqObj = {
                        "idUser": data['data']['userData']['userId'],
                        "idBranch": this.dataService.getCookie('plantId') === '-1' ? null : this.dataService.getCookie('plantId'),
                      };
                      let _sessionObj = Object.assign({},data['data']['userData'],sessionData['data']['companyData'], {});
                      this.dataService.setSessionObj(_sessionObj);
                      if (this.dataService.getCookie('plantId') === '-1') {
                        accessReqObj['plantIds'] = this.dataService.getAllPlantsByCurrCompany(this.dataService.getCookie('companyId'));
                      }
                      this.dataService.callRestful('POST', environment.URLS.AUTH_URL + this.getUrls().UM.AUTH.GET_BRANCH_ACCESS, accessReqObj).subscribe(
                          (accessData) => {
                            if(accessData['successful']){
                              let _sessionObj = Object.assign({},data['data']['userData'],sessionData['data']['companyData'],accessData['data']['branchModules']);
                              this.dataService.setSessionObj(_sessionObj);
                            }
                          });
                    }
                  });
            }
          });
  }

  getSelectedCompDetails(plantId){
    this.settings.selectedCompDetails = {};
    let req = {idBranch:plantId}
    this.dataService.callRestful('POST', environment.URLS.AUTH_URL + this.getUrls().UM.PLANTS.GET_PLANT, req)
          .subscribe((response) => {
            if(response && response.successful && response.status == 200 && response.data){
              this.settings.selectedCompDetails = response.data;
            }
          })
  }

}
