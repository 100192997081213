import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-appredirect',
  templateUrl: './appredirect.component.html',
  styleUrls: ['./appredirect.component.scss']
})
export class AppredirectComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    let userAgent = navigator['userAgent'] || navigator.vendor || window['opera'];
    if (/windows phone/i.test(userAgent)) {
      this.document.location.href = 'https://play.google.com/store/apps/details?id=com.moglix.buyers&hl=en_IN';
    }
    else if (/android/i.test(userAgent)) {
      this.document.location.href = 'https://play.google.com/store/apps/details?id=com.moglix.buyers&hl=en_IN';
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    else if (/iPad|iPhone|iPod/.test(userAgent) && !window['MSStream']) {
      this.document.location.href = 'https://apps.apple.com/us/app/moglix-for-enterprise/id1497681021';
    }
    else {
      this.document.location.href = 'https://play.google.com/store/apps/details?id=com.moglix.buyers&hl=en_IN';
    }
  }

}
