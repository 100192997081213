/**
 * Created by kuldeep on 12/6/17.
 */
export const GLOBAL_CONSTANT = {
    loginByEmail: 1,
    loginByPhone: 2,
    newAddress: 9999,
    created: 1,
    updated: 2,
    creditDebitCard: 1,
    netBanking: 2,
    wallet: 3,
    emi: 4,
    cashOnDelivery: 5,
    neftRtgs: 6,
    savedCard: 7,
    walletPaytm: 1,
    walletPayu: 2,
    walletMobikwik: 3,
    headerType: {
        "default": 1,
        "assist": 2
    },
    expMons: [{key: '01', value: 'JAN'}, {key: '02', value: 'FEB'}, {key: '03', value: 'MAR'}, {key: '04', value: 'APR'}, {key: '05', value: 'MAY'}, {key: '06', value: 'JUN'}, {key: '07', value: 'JUL'}, {key: '08', value: 'AUG'}, {key: '09', value: 'SEP'}, {key: '10', value: 'OCT'}, {key: '11', value: 'NOV'}, {key: '12', value: 'DEC'}],
    default: {
        pageSize : 40
    },
    priceSymbol: {
        'INR': '₹',
        'USD': 'USD',
        'AED': 'AED',
        'EUR': 'EUR',
        'SR': 'SR',
        'KD': 'KD‎'

    },
    codMin: 300,
    codMax:10000,
    userType:{
        business: 'business',
        online: 'online'
    },
    exceptionalCompIds : ['61055', '9260', '9279', '9330', '9002', '61051', '9073', '9034', '61054'],
    exceptionalCompIds2 : ['61054', '61097', '9151', '9291', '61058', '61091'],
    multiVendorCompIds : ['61055', '9260', '9279'],
    compIdsWithOneStepApprovalFlow: ['61051', '9002', '9374'],

    superAdminIds: ["277","278","445","8865","8921","8941","9097","9101","9214","9434",
    "9537","9539","9585","9586","9592","9593","9594","9597","9599","9609",
    "9638","9640","9641","9679","9687","9690","9725","9736","9750","9771",
    "9783","9839","9986","10086","10148","10159","10160","10173","10252","10266","10282",
    "10286","10290","10354","10392","10397","10398","10401","16039","16415","16541","16542",
    "16543","16545","16548","16549","16550","16556","16669","16873","16894","16902","16908",
    "16914","16918","16976","16977","16978","16979","16980","16981","16982","16985","17031",
    "17048","17123","17154","17157","17185","17244","17338","17348"],

}
