import {Component} from '@angular/core';

import {BaMsgCenterService} from './baMsgCenter.service';
import {DataService} from "../../../pages/data.service";
import {params} from "../../../pages/params";
import {urls} from "../../../pages/urls";
import {WsService} from "../../../pages/ws.service";
// import moment = require("moment");
import * as moment from 'moment';
import * as _ from 'lodash';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'ba-msg-center',
  providers: [BaMsgCenterService],
  templateUrl: 'baMsgCenter.html',
  styleUrls:['baMsgCenter.scss']
})
export class BaMsgCenter {

  public notifications:Array<Object>=[];
  public totalNotifications:any;
  public notificationTitle = {
    "purchaserequest": "Purchase Request",
    "purchaseorder": "Purchase Order",
    "rfq": "RFQ",
    "vendor": "Vendor",
    "product": "Product",
    "asn" : "ASN",
    "auction": "Auction",
    "gateentry": "Gate Entry",
    "grn" : "GRN",
    "inventory": "Inventory"
  }

  public notificationMsg = {
    "approval": "Approval request sent by ",
    "approved": "Approved by ",
    "created": "Created by ",
    "updated": "Updated by ",
    "deleted": "Deleted by ",
    "*": "Created by "
  }
  // = [
  //   {
  //     title: 'Vlad',
  //     text: 'Vlad posted a new article.',
  //     time: '1 min ago'
  //   },
  //   {
  //     title: 'Kostya',
  //     text: 'Kostya changed his contact information.',
  //     time: '2 hrs ago'
  //   },
  //   {
  //     title: 'assets/img/shopping-cart.svg',
  //     text: 'New orders received.',
  //     time: '5 hrs ago'
  //   },
  //   {
  //     name: 'Andrey',
  //     text: 'Andrey replied to your comment.',
  //     time: '1 day ago'
  //   },
  //   {
  //     title: 'Nasta',
  //     text: 'Today is Nasta\'s birthday.',
  //     time: '2 days ago'
  //   },
  //   {
  //     title: 'assets/img/comments.svg',
  //     text: 'New comments on your post.',
  //     time: '3 days ago'
  //   },
  //   {
  //     title: 'Kostya',
  //     text: 'Kostya invited you to join the event.',
  //     time: '1 week ago'
  //   }
  // ];

  public messages:Array<Object>;

  constructor(private _baMsgCenterService:BaMsgCenterService, private _service:DataService, private ws:WsService) {
    // this.notifications = this._baMsgCenterService.getNotifications();
    // this.messages = this._baMsgCenterService.getMessages();
    // this._service.callRestful('GET',environment.URLS.PROCUREMENT_URL+urls.NOTIFY.GET_ALL).subscribe(
    //   (data) => {
    //     // console.log(data._body);
    //     $('#loader').css('display', 'none');
    //   }
    // );
    let queryParams ={idUser: this._service.getUserId(), plantId:this._service.getBuyerPlantId(), acknowledged:false};
    this._service.callRestful('POST',environment.URLS.PROCUREMENT_URL+urls.NOTIFY.GET_ALL_ES, this._service.getElasticQuery(queryParams)).subscribe(
      (data) => {
        // // console.log("notificationnnnnnnnnnnnnnnnnnnnnnnnnnnnnn ",data);
        if(data['hits']){
          this.makeNotificationData(this._service.convertEStoMongo(data['hits']['hits']));
          this.totalNotifications = data['hits']['total'];
        }
        $('#loader').css('display', 'none');
      }
    );

    setInterval(() =>{
      if(this.ws.wsData) {
        this.ws.wsData.next({});
      }
    }, 4000);

    this.ws.connect("?idUser="+this._service.getUserId()+"&token="+this._service.getToken()+"&buyerId="+this._service.getBuyerPlantId());
    this.ws.wsData.subscribe(
      (rawData) => {
        // // console.log(rawData);
        let notifyData = rawData;
        if(notifyData['notifications.created']){
          // // console.log(notifyData['notifications.created']);
          this.totalNotifications++;
          this.addNotification(notifyData['notifications.created'])
        }

      },
      (error) => { // console.log(error)},
      () => this._service.showMessage("error","Disconnected...");
      });
  }

  public makeNotificationData(data){
    // // console.log("N Data:",data);
    let _notificationDataset:any[]=[];
    for(let row in data){
      let nType = data[row].topic.split(".");
      let _notificationData = {
        title: this.notificationTitle[nType[0]]+" ("+data[row].docId+")",
        text: data[row].msg.createdByName?this.notificationMsg[nType[1]]+data[row].msg.createdByName+" on "+moment(data[row].msg.modificationDate).format("DD/MM/YYYY hh:mm a"):moment(data[row].msg.modificationDate).format("DD/MM/YYYY hh:mm a"),
        url: data[row].topic
      }

      if(nType[0]=="purchaserequest"){
        if(nType[1]=="approval"){
          _notificationData['url'] = "/pages/pr/viewApproval";
          _notificationData['params'] = {"prId" : data[row].docId};
        }
        else {
          _notificationData['url'] = "/pages/pr/details";
          _notificationData['params'] = {"prId" : data[row].docId};
        }
      }
      else if(nType[0]=="purchaseorder"){
        if(nType[1]=="approval"){
          _notificationData['url'] = "/pages/po/viewApproval";
          _notificationData['params'] = {"poId": data[row].docId};
        }
        else {
          _notificationData['url'] = "/pages/po/asn/itemlist";
          _notificationData['params'] = {"poId": data[row].docId};
        }
      }
      else  if(nType[0]=="rfq"){
        if(this._service.getViewSetting()){
          _notificationData['url'] = "/pages/rfq/quotation/items";
        }
        else {
          _notificationData['url'] = "/pages/rfq/supplierslist2";
        }
        _notificationData['params'] = {"packId" : data[row].docId};
      }
      _notificationDataset.push(_notificationData);
    }
    this.notifications = _.cloneDeep(_notificationDataset);
    // // console.log("nfications: ", this.notifications);
  }

  public addNotification(data){
    // // console.log("data...", data);
    // // console.log("data...", data['creationDate']);
    let nType = data.topic.split(".");
    let _notificationData = {
      title: this.notificationTitle[nType[0]]+" ("+data.docId+")",
      text: data.msg.createdByName?this.notificationMsg[nType[1]]+data.msg.createdByName+" on "+moment(data.msg.modificationDate).format("DD/MM/YYYY hh:mm a"):moment(data.msg.modificationDate).format("DD/MM/YYYY hh:mm a"),
      url: data.topic
    }
    if(nType[0]=="purchaserequest"){
      _notificationData['url'] = "/pages/pr/details";
      _notificationData['params'] = {"prId" : data.docId};
    }
    else if(nType[0]=="purchaseorder"){
      _notificationData['url'] = "/pages/po/asn/itemlist";
      _notificationData['params'] = {"poId" : data.docId};
    }
    else  if(nType[0]=="rfq"){
      if(this._service.getViewSetting()){
        _notificationData['url'] = "/pages/rfq/quotation/items";
      }
      else {
        _notificationData['url'] = "/pages/rfq/supplierslist2";
      }
      _notificationData['params'] = {"packId" : data.docId};
    }
    this.notifications.push(_notificationData)
    this.notifications = _.cloneDeep(this.notifications);
  }

  public openNotifications(){
    // // console.log("acknolwdged");
    this._service.callRestful('POST',environment.URLS.PROCUREMENT_URL+urls.NOTIFY.ACKNOWLEDGE, {"idUser": this._service.getUserId(),
      "plantId":this._service.getCurrBranch(), "acknowledged":false, "notificationType":"notification"}).subscribe(
      (data) => {
        // // console.log(data);
      }
    );
  }


}
