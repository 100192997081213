
import {map} from 'rxjs/operators';
/**
 * Created by akul on 6/3/17.
 */
import {Injectable} from '@angular/core';
import {Observable, Subject, Observer} from 'rxjs';
import {params} from "./params";
import {urls} from "./urls";
import {environment} from "../../environments/environment";

@Injectable()
export class WsService {
  public subjectData: Subject<any>;
  public wsData: Subject<any> = new Subject<any>();

  constructor(){}

  public connect(_queryParams){
    // this.subjectData = null;
    this.wsData = <Subject<any>>this.connectData(environment.URLS.WS_URL+urls.WS.NOTIFY+_queryParams).pipe(
      map((response: any): any => {
        return response.data;
      }));
  }

  public connectData(url: string): Subject<any> {
    if (!this.subjectData) {
      // console.log("Creating new");
      this.subjectData = this.createData(url);
    }
    return this.subjectData;
  }

  public createData(url: string): Subject<any> {
    let ws = new WebSocket(url);

    let observable = Observable.create(
      (obs: Observer<any>) => {
        ws.onmessage = obs.next.bind(obs);
        ws.onerror   = obs.error.bind(obs);
        ws.onclose   = obs.complete.bind(obs);

        return ws.close.bind(ws);
      });

    let observer = {
      next: (data: Object) => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify(data));
        }
      },
      complete: () => {
        // console.log("Closing...");
        ws.close();
      }
    };

    return Subject.create(observer, observable);
  }
}
