/**
 * Created by akul on 16/1/17.
 */
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import { Inject, Injectable } from "@angular/core";
import {BaThemePreloader} from "../theme/services/baThemePreloader/baThemePreloader.service";
import {BaThemeSpinner} from "../theme/services/baThemeSpinner/baThemeSpinner.service";

@Injectable()
export class HideLoaderGuard implements CanActivate {
  constructor(@Inject(BaThemeSpinner)public _spinner: BaThemeSpinner){ }

  canActivate(route:ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean{
        // console.log("inside hide loader gurad");
        BaThemePreloader.load().then((values) => {
          this._spinner.hide();
        });
        return true;
  }
}
