import {Component, ViewEncapsulation, Input, Output, EventEmitter, AfterViewChecked} from '@angular/core';
import {Router, Routes, NavigationEnd} from '@angular/router';
import {Subscription} from 'rxjs';

import { BaMenuService } from '../../services';
import {GlobalState} from '../../../global.state';
import {DataService} from "../../../pages/data.service";
import {params} from "../../../pages/params";
import {urls} from "../../../pages/urls";
@Component({
  selector: 'ba-menu',
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'baMenu.html',
  styleUrls:['baMenu.scss']
})
export class BaMenu {

  @Input() sidebarCollapsed:boolean = false;
  @Input() menuHeight:number;

  @Output() expandMenu = new EventEmitter<any>();

  public menuItems: any[];
  protected _menuItemsSub: Subscription;
  public showHoverElem:boolean;
  public hoverElemHeight:number;
  public hoverElemTop:number;
  protected _onRouteChange:Subscription;
  public outOfArea:number = -200;
  isMoglixCatalogSelected = false;
  // public plantIds: any[];
  version:any;
  constructor(private _router:Router, private _service:BaMenuService, private _state:GlobalState, private _data:DataService) {
    //this.checkIfMoglixCatalogSelected();
    this.version = params.version;
    this._onRouteChange = this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.menuItems) {
          this.selectMenuAndNotify();
        } else {
          // on page load we have to wait as event is fired before menu elements are prepared
          setTimeout(() => this.selectMenuAndNotify());
        }
      }
    });

    this._menuItemsSub = this._service.menuItems.subscribe(this.updateMenu.bind(this));
    // this.plantIds = this._data.getSessionObj()["branches"];
    // console.log(this.menuItems);
  }

  // isInit=false;
  // ngAfterViewChecked(){
  //   if(!this.isInit) {
  //     this.isInit = true;
  //     $('.selected-value').html(this.branches[0] + '<i class="fa fa-caret-down pull-right"></i>');
  //     $('.select-value').children().get(0).innerHTML = this.branches[0] + '<i class="fa fa-check pull-right"></i>';
  //   }
  // }

  public updateMenu(newMenuItems) {
    this.menuItems = newMenuItems;
    this.selectMenuAndNotify();
  }

  public selectMenuAndNotify():void {
    console.log('menu items', this.menuItems)
    if (this.menuItems) {
      // // console.log(this._service.getCurrentItem());

      this.menuItems = this._service.selectMenuItem(this.menuItems);
      this._state.notifyDataChanged('menu.activeLink', this._service.getCurrentItem());
    }
  }
  /*checkIfMoglixCatalogSelected() {
    console.log('catalog value ', this.isMoglixCatalogSelected)
    const getModulesUrl = environment.URLS.AUTH_URL + urls.UM.COMPANY.GET_MODULES;
    const getAllModulesRequestBody = {
      "idCompany" : this._data.getCurrCompanyID()
    };
    this._data.callRestful("POST", getModulesUrl, getAllModulesRequestBody).subscribe(data => {
      if (data["successful"]) {
        this.isMoglixCatalogSelected = false;
        const modules = data["data"]["companyModules"];
        for (const i of modules) {
          if(i.displayName == "Moglix Catalog"){
            this.isMoglixCatalogSelected = true;
            console.log('catalog value', this.isMoglixCatalogSelected)
            break;
          }
        }
        console.log('catalog value outside', this.isMoglixCatalogSelected)
      }
      else{
        this.isMoglixCatalogSelected = false;
        console.log('catalog value outside success', this.isMoglixCatalogSelected)

      }
    });
  }*/

  // branches:any[] = [];
  // branchNames:any={};
  // branchToCompany:any={}
  // public ngOnInit():void {
  //   this.branches = this._data.getSessionObj()["branches"];
  //   this.branchNames = this._data.getSessionObj()["branchNames"];
  //   this.branchToCompany = this._data.getSessionObj()["branchToCompany"];
  // }

  public ngOnDestroy():void {
    this._onRouteChange.unsubscribe();
    this._menuItemsSub.unsubscribe();
  }

  public hoverItem($event):void {
    this.showHoverElem = true;
    this.hoverElemHeight = $event.currentTarget.clientHeight;
    // TODO: get rid of magic 66 constant
    this.hoverElemTop = $event.currentTarget.getBoundingClientRect().top - 66;
  }

  public toggleSubMenu($event):boolean {
    var submenu = jQuery($event.currentTarget).next();

    if (this.sidebarCollapsed) {
      this.expandMenu.emit(null);
      if (!$event.item.expanded) {
        $event.item.expanded = true;
      }
    } else {
      $event.item.expanded = !$event.item.expanded;
      submenu.slideToggle();
    }

    return false;
  }

  // public changePlantId(plantId){
  //   // console.log("Got Plant ID:"+plantId);
  //   if(plantId.toString() != this._data.getBuyerPlantId()) {
  //     this._state.notifyDataChanged('plantId', plantId);
  //     this._router.navigateByUrl('/pages');
  //   }
  // }



  // public toggleDropdown(){
  //   if( $('.selected-value').hasClass('slide-down') ){
  //       $('.selected-value').removeClass('slide-down');
  //       $('.select-value').slideUp(300);
  //       $('.selected-value').removeClass('slide-down');
  //       $('.selected-value i').removeClass('fa fa-caret-up pull-right').addClass('fa fa-caret-down pull-right')
  //   }
  //   else {
  //       $('.selected-value').addClass('slide-down');
  //       $('.selected-value i').removeClass('fa fa-caret-down pull-right').addClass('fa fa-caret-up pull-right')
  //       $('.select-value').slideDown(300);
  //     $('.select-value li').unbind().click(function() {
  //         $('.select-value li').removeClass('selected-class');
  //         $('.select-value li i').remove();
  //         $(this).addClass('selected-class');
  //         $(this).append('<i class="fa fa-check pull-right"></i>');
  //         $('.selected-value').html($(this).text() + '<i class="fa fa-caret-down pull-right"></i>');
  //         $('.select-value').hide();
  //     });
  //   }
  // }
}
