import { Injectable } from '@angular/core';

export class Settings {
    constructor(
                public mmSearchValue:any,
                public selectedCompDetails:any,
                public userListByBranch: any,
                public company: any,
                public isDesktop: any,
                ) { }
}

@Injectable()
export class AppSettings {
    public settings = new Settings(
        '',
        {},
        [],
        {},
        false
    )
}