import {Component, ViewEncapsulation, Input, OnInit, Output, EventEmitter, OnChanges} from '@angular/core';
// import {LocalDataSource} from "ng2-smart-table";
// import {LocalDataSource} from "../ng2-smart-table/build/src/ng2-smart-table/lib/data-source/local/local.data-source";
import { LocalDataSource } from 'ng2-smart-table';

@Component({
  selector: 'smart-tables',
  // encapsulation: ViewEncapsulation.None,
  templateUrl: 'smartTables.html',
  styleUrls:['smartTables.scss']
})
export class SmartTables implements OnInit,OnChanges{

  public searchQuery: any[] = [];

  @Input() smartColumns:any;
  @Input() smartData:any[];
  @Input() isSearchHide:boolean = false;

  @Output() cellEnter:EventEmitter<any> = new EventEmitter();
  @Output() rowClicked:EventEmitter<any> = new EventEmitter();
  @Output() cellLeave:EventEmitter<any> = new EventEmitter();
  @Output() cellSelectAll:EventEmitter<any> = new EventEmitter();
  @Output() cellCheckboxChange:EventEmitter<any> = new EventEmitter();
  @Output() cellRadioChange:EventEmitter<any> = new EventEmitter();

  settings = {
    actions: {
      edit: false,
      delete: false,
      add: false
    },
    columns:{}
  };

  source: LocalDataSource = new LocalDataSource();

  constructor() {

  }

  ngOnChanges(){
    // console.log("Done");
    this.source.load(this.smartData);
  }

  ngOnInit(){
    this.settings.columns = this.smartColumns;
    this.source.load(this.smartData);
    for(var key in this.smartColumns){
      this.searchQuery.push({field:key,search:''});
    }
  }

  onSearch(query: string = ''): void {
    for(var key in this.searchQuery){
      this.searchQuery[key].search = query;
    }

    this.source.setFilter(this.searchQuery, false);

  }

  onMouseEnter(event){
    this.cellEnter.emit({row:event.row,cell:event.cell});

  }
  onMouseLeave(event){
    this.cellLeave.emit({row:event.row,cell:event.cell});
  }

  onSelectAll(event){
    this.cellSelectAll.emit(event);
  }

  onCheckboxChange(event){
    this.cellCheckboxChange.emit(event);
  }

  onRadioChange(event){
    this.cellRadioChange.emit(event);
  }

  onRowClicked(e){
    this.rowClicked.emit(e);
  }
}
