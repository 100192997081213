import { Injectable } from '@angular/core';

@Injectable()
export class DaterangepickerConfig {

    public settings: any;

    constructor() {
        this.settings = {};
    }

}
