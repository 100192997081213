import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from "@angular/router";
 import {Observable} from "rxjs";
 import { Injectable } from "@angular/core";

 @Injectable()

 export class IsLoginGuard implements CanActivate {
   constructor(private _router: Router){ }

   canActivate(route:ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean{
       // if user is still logged in, redirect to orders page
       let token = localStorage.getItem('moglixB2BToken');
       if(token && token?.length > 0) {
         this._router.navigateByUrl('/pages/orders/po');
       }
       return true;
   }
 }