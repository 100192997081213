/**
 * Created by akul on 18/1/17.
 */
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from "@angular/router";
import {Observable} from "rxjs";
import { Inject, Injectable } from "@angular/core";
import {DataService} from "./data.service";

@Injectable()
export class WriteableGuard implements CanActivate {
  constructor(@Inject(DataService) public  _dataService: DataService, @Inject(Router)public router:Router){ }

  redirectMap = {
    'pr':'/pages/pr/pr-list',
    'rfq':'/pages/pr/pr-list',
    'po':'/pages/orders/po/po-list',
    // 'grn':'',
    'ge':'/pages/ge/ge-list'
    // 'pm':''
    // 'pmt':''
    // 'si':''
    // 'um':''
    // 'sm':''
  };

  canActivate(route:ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean{
    // console.log("Checking Access",route.parent.parent.routeConfig.path,this._dataService.getModulePermission(route.parent.parent.routeConfig.path.toUpperCase()));
    if(this._dataService.getModulePermission(route.parent.parent.routeConfig.path.toUpperCase())<2){
      // console.log(this._dataService.getCurrentPermissions());
      // console.log("No Access",route.parent.parent.routeConfig.path);
      this.router.navigateByUrl(this.redirectMap[route.parent.parent.routeConfig.path]);
      return false;
    }
    return true;
  }
}
