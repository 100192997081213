export const urls = {
  checkAccess : "auth/checkAccess",
  ANALYTICS:{
    DAILY_SALES : {
      SEARCH : "analytics/dailySales/es/search"
    },
    DAILY_SALES_ITEM : {
      SEARCH : "analytics/dailySalesItemWise/es/search"
    },
    ITEM_STATUS_STATS : {
      DELIVERY: "analytics/itemstats/delivery",
      SEARCH: "analytics/itemstats/es/search",
      PO_PROCESS_TREND: "analytics/itemstats/processtrend",
      PAYMENT : "invoice/payments/get"
    }
  },
  GST : {
    TAX_RATE_BY_HSN: "/gstkb/hsn/taxrate"
  },
  PR : {
    SEARCH_PR: "pr/",
    FETCH_ALL: "pr/search",
    FETCH_ALL_ES: "search/pr/es/search",
    GET_COUNT: "pr/count",
    CREATE_PR : "pr/create",
    PACKAGE_SEARCH: "package/search",
    PACKAGE_ES_SEARCH: "package/es/search",
  },

  BI:{
    GET_TRANSIT_SHIPMENTS:"asn/es/search",
    GET_PO_VS_TIME:"po/es/search",
    GET_PoITEM_VS_TIME:"item/es/search"
  },
  RFQ: {
    RFQ_SEARCH: "rfq/search",
    RFQ_ES_SEARCH : "rfq/es/search",
    RELEASE_RFQ : "rfq/create"
  },
  RFQNEWSEARCH: {
    CREATE_RFQ : "rfqnew/create",
    VIEW_RFQ : "rfqnew/search"
  },
  RFQNEW: {
    CREATE_RFQ : "rfqnew/create",
    VIEW_RFQ : "rfqnew/get"
  },
  RFQNEWEOC: {
    CREATE_RFQ : "po/rfqitem/create",
    VIEW_RFQ : "rfqnew/get"
  },
  WS:{
    BID:"bid/socket",
    NOTIFY:"notification/socket"
  },
  NOTIFY: {
    GET_ALL:"notification/getAll",
    GET_ALL_ES:"notification/es/search?pageSize=10",
    ACKNOWLEDGE: "notification/ack"
  },

  ASN: {
    SEARCH_ASN : 'asn/search',
    SEARCH_ES_ASN: 'asn/es/search'
  },
  PMT: {
    FETCH_ALL : 'payment/search',
    GET_BY_ID : "payment/",
    GET_PLANT_BALANCE: 'api/customerPayout/getPlantBalances',
    GET_ALL_CUSTOMER_PAYMENT: 'api/customerPayout/getAllCustomerPayments',
    GET_ALL_INVOICETOCUSTPAY:'api/customerPayout/getAllInvoicesMappedToCustomerPayment',
    GET_ALL_INVOICES:'api/customerPayout/getAllPayableInvoices',
    GET_ALL_INVOICES_HISTORY: 'api/customerPayout/getInvoicePaymentHistory',
    GET_PAYMENT_TYPE: 'api/customerPayout/getPaymentTypes'
  },
  ITEM: {
    SEARCH : 'item/search',
    GET_COUNT: "item/count"
  },
  GRN: {
    SEARCH_GRN : 'grn/search',
    CREATE_GRN : 'grn/create',
    GRN_SEARCH_ELASTIC: 'grn/es/search'
  },
  INV:{
    GET_INVOICE : 'invoice/getFromEms',
  },
  GE: {
    SEARCH_GE : 'ge/search',
    CREATE_GE : 'ge/create'
  },
  AUCTION: {
    SEARCH : 'auction/search',
    CREATE : 'auction/create',
    UPDATE : 'auction/update',
    BID_SEARCH : 'bid/search',
    SORTED_BID : 'bid/bidwiseSorting'
  },
  AUTH : {
    LOGIN : "auth/login",
    GET_HOOKURL : "user/getHookUrl",
    LOGOUT : "auth/logout",
    FORGET : "auth/forgotPassword",
    RESET: "auth/resetPassword",
    RESET_KEY: "auth/resetPasswordValidation",
    GET_SESSION: "user/getSession",
    // GET_BRANCH_ACCESS: "user/getBranchAccess",
    GET_IS_SAP_INTREGATED: "company/get",
    GET_COMPANY_DETAILS: "company/get",
    REGISTER: "",
    // CHECK_ACCESS : "auth/checkAccess",
    GET_ALL_STATE : "address/state/getAll",
    GOOGLE_LOGIN : "auth/loginwithsso",
    GENERATE_OTP: "auth/generateAndSendEmailOtp",
    VALIDATE_OTP: "auth/validateOtpAndLogin"
  },
  PRODUCT : {
    COUNT:"product/count",
    FETCH_ALL : "product/search",
    FETCH_ALL_ES: "product/es-search",
    FETCH_ALL_ES_PARTIAL: "product/es-search_partial",
    ADD : "product/create",
    GET_BY_ID : "product/",
    GET_ALL_CATEGORY : "category/search",
    CREATE_CATEGORY : "category/create",
    CREATE_MULTIPLE_CATEGORY : "category/multicreate",
    CREATE_BRAND : "brand/create",
    CREATE_TYPE : "type/create",
    GET_ALL_UNIT : "unit/search",
    GET_ALL_TYPE : "type/search",
    GET_ALL_BRAND : "brand/search",
    GET_ALL_BRAND_ES : "brand/es/search",
    GET_ALL_TAX_TYPE : "brand/search",
    UPLOAD : "product/upload",
    GET_PRODUCTS_FROM_CPNS: "product/getProductsFromCpns",
    GET_TAX_RATE_FROM_HSN: "product/hsn/taxrate",
    GET_UOM: "unit/search",
    GET_SUPPLIER_PRODUCT:"msn-supplier",
    ES_FETCH_ALL_PRODUCTS : "product/es-search"
  },
  VENDOR : {
    FETCH_ALL : "vendor/search",
    FETCH_ES_ALL : "vendor/es/search",
    ADD : "vendor/create",
    GET: "vendor",
    GET_BY_ID : "vendor/getById",
    UPLOAD : "vendor/upload",
    GET_ALL : "vendor/getAll",
    CREATE : "vendor/create"
  },
  CART : {
    UPSERT_PRODUCT : "cart/upsert",
    REMOVE_PRODUCT : "cart/remove",
    FLUSH_CART : "cart/empty",
    FETCH_CART : "cart/get",
    PR:"/pr",
    PO:"/po",
    RFQ:"/rfq",
    GET_CONFIG: "pages/getConfig",
    REMOVE_VENDOR_ITEM : 'cart/po/remove-item',
    CXML_CHECKOUT : 'cart/checkoutcxml/po'
  },
  PO : {
    GET_BY_ID : "po/",
    CREATE_PO : "po/create",
    UPDATE_PO_QTY : "po/updateQty",
    GET_BY_CODE : '/order/getByCode',
    GET_ALL : "po/search",
    GET_ALL_ES : "po/es/search",
    COUNT:"po/count",
    GET_ALL_ITEM : "order/item/getAll",
    GET_ORDER_STATUS : "data/orderStatus/getAll",
    GET_PAYMENT_TERMS : "data/paymentTerms/getAll",
    DOWNLOAD_ORDER : '/download/order',
    DOWNLOAD_ORDERS : 'po/download/orders',
    DOWNLOAD_ORDER_ITEM : '/download/orderItem',
    GET_POSSIBLE_ITEM_STATUS : '/order/getPossibleActions',
    UPDATE_ORDER_STATUS : '/order/updateOrderStatus',
    UPDATE_ORDER_ITEM_STATUS : '/order/updateOrderItemStatus',
    GET_ITEMS: 'item/es/search',
    GET_ITEMS_V2: 'item/es/search/v2',
    GET_VENOR_BUYER_PTS :'terms/vendor/',
    UPDATE_PO: 'po/',
    GET_CONFIGURATION_BY_COMPANY: 'configuration/getConfig',
    AUDIT_ITEM: 'poaudit/item/',
    AUDIT_LOGS: 'poaudit/logs/',
    AUDIT: 'poaudit/',
    GET_CONFIG: 'poPage/headerConfig'
  },
  SM : {
    ADD : "sm/create",
    GET_ALL : "sm/getAll",
    UPLOAD : "sm/upload",
    GET_BY_ID : "sm/get",
    UPDATE_BRANCH : "sm/updateBranch",
    UPDATE_ADDRESS : "sm/updateAddress",
  },
  SI : {
    SEARCH : "inventory/search",
    COUNT : "inventory/count",
    CREATE : "inventory/create"
  },
  UM : {
    COMPANY:{
      // GET_MODULES:"companyModule/getAll",
      CREATE:"company/create",
      UPDATE:"company/update",
      // MODULE_PERMISSION:"companyModule/update",
      GET_VENDOR_CODE : "company/getVendorCode"
    },
    ROLES:{
      // GET: "role/getCompanyRoles",
      // CREATE: "role/create",
      // UPDATE: "role/update",
      DELETE: "role/delete"
    },
    PLANTS:{
      // GET:"branch/getAllByCompany",
      GET_ADDRESS_BY_BRANCH: "address/getAddressByBranch",
      // GET_PLANT:"branch/getDetails",
      // CREATE_MULTIPLE:"/branch/createMultiple",
      // CREATE: "branch/create",
      // UPDATE: "branch/updateDetails",
      // UPDATE_ADDRESS: "address/createBranchAddMap",
      UPDATE_DEFAULT_ADDRESS: "address/branch/setDefault",
      UPDATE_ADDRESS_BRANCH: "address/update",
      BUDGET_UPDATE_CHECK: "po/budgetUpdateCheck",
      GET_VENDOR_CODES:"branch/getVendorMappings",
      GET_VENDOR_CODE:"branch/getVendorMappingByBranchAndVendor",
      UPDATE_VENDOR_CODE:"branch/insertVendorMapping",
      PLANT_MAPPING: "rest/plant/createUpdateCustomerPlantCode",
      GET_CUSTOMER_PLANT_CODE: "rest/plant/getCustomerPlantCode",
      DELETE_CUSTOMER_PLANT_CODE:"rest/plant/deleteCustomerPlantCode",
      VENDOR_MAPPING: "rest/vendor/addUpdateVendorCode",
      GET_CUSTOMER_VENDOR_CODE: "rest/vendor/getCustomerVendorCode",
      // DELETE_ADDRESS: "address/deleteBranchAddMap"
      DELETE_CUSTOMER_VENDORCODE:"rest/vendor/deleteVendorCode"
    },
    USERS:{
      // GET: "user/getByBranch",
      // GET_FOR_COMPANY:"user/getByCompany",
      GET_DESIGNATION: 'designation/get',
      ASSIGN_ROLE: "user/assignRole",
      // CREATE: "auth/register",
      UPDATE_ACTIVE_STATE:"user/updateStatus",
      UPDATE_ACCESS:"user/updateAccess",
      GET_AUDIT_TRIAL_ACCESS:"user/getAccess",
      UPDATE_NOTIFY_STATE:"user/updateNotifyStatus",
      // GET_USER:"user/get",
      UPDATE_PWD:"user/updatePassword",
      UPDATE_DETAILS:"user/updateDetails",
      GET_ROLES:"user/getRolesByBranch",
      SAVE_ROLES:"user/assignRolesForBranches",
      INSERT_DEFAULT_PLANT:"user/insertDefaultBranch",
      GET_DEFAULT_PLANT:"user/insertDefaultBranch"
    },
    GET_MODULE_WISE_USERS : "module/getUsers",
    MODULE : {
      // GETALL : "module/getAppModules"
    }
  },
  SETTING:{
    UPSERT : "setting/upsert",
    GET : "setting"
  },
  ERROR : {
    LOG_ERROR : "error/mail"
  },
  QUOTE :
  {
    LOG_MAIL : "quoteReply/mail"
  },
  SEARCHBRANCH : {
    SEARCHBRANCH : "branch/searchPlants/"
  },
  SEARCHCOMPANY : {
    SEARCHCOMPANY : "branch/searchPlantsByCompany/"
  },
  DASHBOARD: {
    ONTIME_SUPPLIES : "dashboard/getOnTimeSupplies",
    DELIVERY_BREAKUP : "dashboard/getDeliveryBreakup",
    MAVERICK_PLANNED: "dashboard/maverick-planned",
    TOP_CATEGORIES: "dashboard/topCategories",
    DEFECT_RATE: "dashboard/defect-rate",
    SPEND_ANALYTICS: "dashboard/spendAnalytics",
    ARC_NONARC: "dashboard/arc-nonarc",
    RFQ_TAT: "dashboard/rfq-tat",
    GET_CONFIG: "dashboard/getConfig",
    SET_CONFIG: "dashboard/setConfig"
  },
  SA: {
    GET_ALL: 'agreement/search',
    GET_ITEMS: 'agreementItem/search',
    GET_BY_ID: 'agreement/',
    GET_ALL_EOC: 'sa/search',
    GET_EOC_DETAILS: 'sa/get-detail',
    EXPORT_TO_CSV: 'agreement/csv_download'
  },
  DS: {
    UPLOAD: 'scheduledelivery/upload',
    GET_ALL: 'scheduledelivery/es/search',
    CREATE: 'scheduledelivery/create',
    APPROVE: 'scheduledelivery/approve',
    CREATE_MR: 'materialreservation/createMultiple',
     DOWNLOAD: 'scheduledelivery/download'
  },
  CATALOG: {
    SEARCH: 'search'
  },
  SPEND_LIMIT: {
    USER: 'spend-limit/user-spend-limit-details-by-branch'
  },
  EMS : {
    GET_INVENTORY : 'api/inventory/getAvailability'
  },
  CENTRAL: {
    // common
    GET_ALL_COUNTRIES: 'country',
    // state // city
    GET_ALL_SUBSIDIARIES: 'subsidiary/get-all',
    GET_COST_CENTRES: 'cost-centre/get',
    GET_REGIONS: 'region/',

    GET_VENDOR_MAP_BY_PLANT: 'v0/plant-vendor/get-plant-vendor-map-by-plant-id/',
    GET_ADDRESS_BY_BRANCH: 'v0/plant-address/get-addr-by-plant-id-and-addr-type',

    GET_KAM_USERS: 'user/kams',

    COMPANY: {
      CREATE: 'company/create'
    },
    // vendor
    VENDOR: {
      GET_ALL: 'vendor/getAll',
      GET_BY_ID: 'vendor/',
      CREATE: 'vendor/create'
    },
    PLANT: {
      CREATE: 'plant/create',
      UPDATE: 'plant/update',
      GET_VENDOR_MAP: 'plant/vendor-mapping',
      INSERT_VENDOR_MAP: 'plant/insert-vendor-mapping',
      MAP_WAREHOUSE: 'plant/insertPlantWarehouseMapping'
    },
    ADDRESS: {
      CREATE: 'plant-address/create-address-for-plant',
      UPDATE: 'plant-address/update-address-for-plant',
      SET_DEFAULT: 'plant-address/setDefault',
      DELETE: 'plant-address/deletePlantAddMap',
    },
    WAREHOUSE: {
      GET_ALL: 'warehouse/getAll'
    }
  },
  PROJECTS: {
    COMPLETION: 'project/complete',
    STATUS: 'project/status',
    ATTACHMENTS: 'attachment/fetch',
    MILESTONE: 'project/milestones',
    ACTIVITY_TRACKER: 'project/activity-tracker',
    SALES_POC: 'project/salespocs',
    PO_DETAILS: 'project/getPoDetails',
    PAYMENT_SCHEDULE: 'project/payment-schedule',
    SEARCH_PROJECTS: 'project/get-plant-projects',
    DOWNLOAD_PO: 'project/downloadPlantProjectsCsv',
    PO_COUNT: 'project/count',
    HEALTH_BY_TASK: 'project/health-by-task',
    POP_UP_DETAILS: 'project/popupDetails',
    ISSUES: 'issue/getIssue',
    RISKS: 'risk/getRisk',
    ADD_ISSUES: 'issue/addIssue',
    ADD_RISKS: 'risk/addRisk'
  }
};
