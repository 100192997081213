import { NgModule, ApplicationRef, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router, RouterModule } from '@angular/router';
import { routing } from './app.routing';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
// import {MatButtonModule, MatCheckboxModule} from '@angular/material/';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import { DragulaModule } from 'ng2-dragula';
//import * as autoScroll from 'dom-autoscroller';
//import { AutoScrollerFactory } from 'dom-autoscroller';

// App is our top level component
import { App } from './app.component';
import { AppState, InternalStateType } from './app.service';
import { GlobalState } from './global.state';
import { NgaModule } from './theme/nga.module';
import { PagesModule } from './pages/pages.module';
import { DataService } from "./pages/data.service";
import { GoogleLoginProvider, SocialLoginModule, SocialAuthServiceConfig, MicrosoftLoginProvider } from 'angularx-social-login';
import {params} from "./pages/params";
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { NgSelectModule } from '@ng-select/ng-select';
import { routes } from './pages/pages.routing';
import {environment} from "../environments/environment";
import { CommonapiService } from './pages/commonapi.service';
import { AppSettings } from './app.settings';
import { HttpInterceptorHandler } from './pages/commons/auth/http.interceptor';
import * as Sentry from "@sentry/angular";
// import { FormsModule  } from '@angular/forms';
// Application wide providers
const APP_PROVIDERS = [
  AppState,
  GlobalState
];

type StoreType = {
  state: InternalStateType,
  restoreInputValues: () => void,
  disposeOldHosts: () => void
};

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [App],
  declarations: [
    App
  ],
  imports: [ // import Angular's modules
    BrowserModule,
    HttpClientModule,
    HttpClientJsonpModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    FormsModule,
    ReactiveFormsModule,
    NgaModule.forRoot(),
    PagesModule,
    routing,
    NoopAnimationsModule,
    BrowserAnimationsModule,
    // MatButtonModule,
    // MatCheckboxModule,
    SocialLoginModule,
    BrowserTransferStateModule,
    DragulaModule.forRoot(),
    //autoScroll,
    //AutoScrollerFactory
    NgSelectModule,
  ],
  exports: [RouterModule],
  providers: [ // expose our Services and Providers into Angular's dependency injection
    // ENV_PROVIDERS,
    APP_PROVIDERS,
    DataService,
    CommonapiService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.URLS.key)
          },
          {
            id: MicrosoftLoginProvider.PROVIDER_ID,
            provider: new MicrosoftLoginProvider(environment.microsoft.CLIENT_ID, {
              authority: `https://login.microsoftonline.com/${environment.microsoft.TENANT_ID}`
            }),
          }
        ]
      } as SocialAuthServiceConfig,
    },
    AppSettings,
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorHandler, multi: true },
    { provide: Sentry.TraceService, deps: [Router] },
    { provide: APP_INITIALIZER, useFactory: () => () => {}, deps: [Sentry.TraceService], multi: true },
  ]
})

export class AppModule {

  constructor(public appRef: ApplicationRef, public appState: AppState) {
  }

  // hmrOnInit(store: StoreType) {
  //   if (!store || !store.state) return;
  //   // console.log('HMR store', JSON.stringify(store, null, 2));
  //   // set state
  //   this.appState._state = store.state;
  //   // set input values
  //   if ('restoreInputValues' in store) {
  //     let restoreInputValues = store.restoreInputValues;
  //     setTimeout(restoreInputValues);
  //   }
  //   this.appRef.tick();
  //   delete store.state;
  //   delete store.restoreInputValues;
  // }

  // hmrOnDestroy(store: StoreType) {
  //   const cmpLocation = this.appRef.components.map(cmp => cmp.location.nativeElement);
  //   // save state
  //   const state = this.appState._state;
  //   store.state = state;
  //   // recreate root elements
  //   store.disposeOldHosts = createNewHosts(cmpLocation);
  //   // save input values
  //   store.restoreInputValues = createInputTransfer();
  //   // remove styles
  //   removeNgStyles();
  // }

  // hmrAfterDestroy(store: StoreType) {
  //   // display new elements
  //   store.disposeOldHosts();
  //   delete store.disposeOldHosts;
  // }
}
