import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from "rxjs";

@Injectable()
export class BaHeaderService {

    searchKey: any = '';

    searchKeySubject = new BehaviorSubject(this.searchKey);
    clearSearchSubject = new BehaviorSubject(this.searchKey);
    callSearchApiSubject = new Subject<void>();

    //getters and setters
    public setSearchKey(Key: any) {
        this.searchKey = Key;
        this.searchKeySubject.next(Key);
        return this.searchKey;
    }

    public clearSearch() {
        this.searchKey = '';
        this.clearSearchSubject.next('');
        return this.searchKey;
    }

    public callSearchApi() {
        this.callSearchApiSubject.next();
    }
}
