/**
 * Created by akul on 16/1/17.
 */
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from "@angular/router";
import {Observable} from "rxjs";
import { Inject, Injectable } from "@angular/core";
import {DataService} from "./data.service";
import {params} from "./params";
import {urls} from "./urls";
import {BaThemePreloader} from "../theme/services/baThemePreloader/baThemePreloader.service";
import {BaThemeSpinner} from "../theme/services/baThemeSpinner/baThemeSpinner.service";

@Injectable()
export class SuperUserGuard implements CanActivate {
  constructor(@Inject(DataService) public  _dataService: DataService, @Inject(Router)public router:Router,@Inject(BaThemeSpinner)public _spinner: BaThemeSpinner){ }

  canActivate(route:ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean{
    return this._dataService.getIsSuperUser();
  }
}
