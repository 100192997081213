import { HttpRequest } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { DataService } from './../data.service';
import { CommonapiService } from '../commonapi.service';
import { urls } from '../urls';
import { METHODS } from 'http';

export const URLS_INFO = {
    ENABLE_TPL_FLOW: { METHOD: 'POST', URL: environment.URLS.EOC_API + 'flow/TPL'},
    GET_TPL_FLAG: { METHOD: 'GET', URL: environment.URLS.AUTH_URL + 'role/isTplCompany/'},
    SAMPLE_UPLOAD_TEMPLATE: { METHOD: 'GET', URL: environment.URLS.EOC_API + 's3/downloadCSVTemplate?templateType=product_upload_template_catalog' },
    SA_UPLOAD_TEMPLATE: { METHOD: 'GET', URL: environment.URLS.EOC_API + 's3/downloadCSVTemplate?templateType=sa_upload_template' },
    DEACTIVATE_TEMPLATE: { METHOD: 'GET', URL: environment.URLS.EOC_API + 's3/downloadCSVTemplate?templateType=product_deactivate_template'},
    COMP_CC_TEMPLATE: { METHOD: 'GET', URL: environment.URLS.EOC_API + 's3/downloadCSVTemplate?templateType=company_cost_centres'},
    PLANT_MAPPING_TEMPLATE: { METHOD: 'GET', URL: environment.URLS.EOC_API + 's3/downloadCSVTemplate?templateType=plant_cost_centre_mapping'},
    UPLOAD_FILE:{ URL: environment.URLS.PROCUREMENT_URL + 'product/upload'},
    UPLOAD_DEACTIVATE_FILE: { URL: environment.URLS.EOC_API + 'catalogue/product/csv_bulk_disable'},
    REFRESH_IMAGE: { METHOD: 'POST', URL: environment.URLS.EOC_API + 'catalogue/product/update-images/?companyId='},
    COMP_CC_FILE: { URL: environment.URLS.AUTH_URL + 'cost-centre/bulk-create'},
    FETCH_COMPANY_CC: { METHOD: 'GET', URL: environment.URLS.AUTH_URL + 'cost-centre/download-by-companyId'},
    UPLOAD_SA_FILE: { URL: environment.URLS.PROCUREMENT_URL + 'agreement/saFromCSV'},
    FETCH_COSTCENTERS: { METHOD: 'POST', URL: environment.URLS.CENTRAL_API + urls.CENTRAL.GET_COST_CENTRES },
    MAP_PLANT_CC: { URL: environment.URLS.AUTH_URL + 'cost-centre/cost-centre-branch-mapping'},
    UPDATE_CC: { METHOD: 'POST', URL: environment.URLS.CENTRAL_API + 'cost-centre/update-cost-centre-plant-mapping'},
    FETCH_MASTER_CC_LIST: { METHOD: 'GET', URL: environment.URLS.CENTRAL_API + 'cost-centre/get-cost-centre-by-companyid-without-check/'},
    GET_FLOW_ENABLE: { METHOD: 'POST', URL: environment.URLS.AUTH_URL + 'role/getFlowEnableStatus'},
    ENABLE_MM_FLOW: { METHOD: 'POST', URL: environment.URLS.EOC_API + 'flow/enbleMMFlow'},
    REPULL_PO:{ METHOD: 'POST', URL: environment.URLS.SAP_ABFRL_URL + 'rest/merchant-po/re-pull-po'},
    REPUSH_PO:{ METHOD: 'POST', URL: environment.URLS.SAP_ABFRL_URL + 'rest/merchant-po/repush-po'},
    // FETCH_PO_SUMMARY: { METHOD: 'GET', URL: environment.URLS.SAP_URL + 'rest/report/poSummeryDetails?type=SAP'},
    FETCH_PO_SUMMARY: { METHOD: 'GET', URL: environment.URLS.REPORT_SERVICE_URL + 'buyerReports/poSummeryDetails?'},
    AUDITPOHISTORY:{ METHOD:'POST', URL:environment.URLS.SEARCH_URL + 'po/poAuditHistory'},
    REPUSH_NON_ABFRL_PO:{METHOD:'POST', URL: environment.URLS.SAP_URL + 'rest/po/repushFailedPo/'},
    GET_HZL_REPORT:{METHOD:'POST',URL: environment.URLS.EOC_API + 'analyticWeb'},
    DOWNLOAF_HZL_REPORT:{METHOD:'POST',URL: environment.URLS.EOC_API + 'exportToExcel'}
}

@Injectable()
export class SupportService
{
    constructor(private _dataService: DataService, private _commonapiService: CommonapiService) { }

    enableTplFlow(request) {
        return this._dataService.callRestful(URLS_INFO.ENABLE_TPL_FLOW.METHOD, URLS_INFO.ENABLE_TPL_FLOW.URL, request);
    }

    getTplFlag(companyId) {
        return this._dataService.callRestful(URLS_INFO.GET_TPL_FLAG.METHOD, URLS_INFO.GET_TPL_FLAG.URL + companyId);
    }

    getSampleUploadTemplate() {
        return this._dataService.callRestful(URLS_INFO.SAMPLE_UPLOAD_TEMPLATE.METHOD, URLS_INFO.SAMPLE_UPLOAD_TEMPLATE.URL);
    }

    getProductDecativateTemplate() {
        return this._dataService.callRestful(URLS_INFO.DEACTIVATE_TEMPLATE.METHOD, URLS_INFO.DEACTIVATE_TEMPLATE.URL);
    }

    uploadFile(form){
        return this._dataService.fileUpload(URLS_INFO.UPLOAD_FILE.URL, form);
    }

    refreshImagesOnCatalog(companyId) {
        return this._dataService.callRestful(URLS_INFO.REFRESH_IMAGE.METHOD, URLS_INFO.REFRESH_IMAGE.URL + companyId, {});
    }

    uploadDeactivateProductsFile(form) {
        return this._dataService.fileUpload(URLS_INFO.UPLOAD_DEACTIVATE_FILE.URL, form);
    }

    uploadCompanyCostCentres(form) {
        return this._dataService.fileUpload(URLS_INFO.COMP_CC_FILE.URL, form);
    }

    getCompanyCostCentresTemplate() {
        return this._dataService.callRestful(URLS_INFO.COMP_CC_TEMPLATE.METHOD, URLS_INFO.COMP_CC_TEMPLATE.URL);
    }
    
    getSaCreationTemplate() {
        return this._dataService.callRestful(URLS_INFO.SA_UPLOAD_TEMPLATE.METHOD, URLS_INFO.SA_UPLOAD_TEMPLATE.URL);
    }

    uploadSaCreationFile(form) {
        return this._dataService.fileUpload(URLS_INFO.UPLOAD_SA_FILE.URL, form);
    }

    getCompanyCostCentresList() {
        return this._dataService.callRestful(URLS_INFO.FETCH_COMPANY_CC.METHOD, URLS_INFO.FETCH_COMPANY_CC.URL);
    }

    getCostCenters(request) {
        return this._dataService.callRestful(URLS_INFO.FETCH_COSTCENTERS.METHOD, URLS_INFO.FETCH_COSTCENTERS.URL, request);
    }

    getPlantCostCentreMappingTemplate() {
        return this._dataService.callRestful(URLS_INFO.PLANT_MAPPING_TEMPLATE.METHOD, URLS_INFO.PLANT_MAPPING_TEMPLATE.URL);
    }

    mapCostCentresByCsv(form) {
        return this._dataService.fileUpload(URLS_INFO.MAP_PLANT_CC.URL, form);
    }

    updateCostCentrePlantMapping(request) {
        return this._dataService.callRestful(URLS_INFO.UPDATE_CC.METHOD, URLS_INFO.UPDATE_CC.URL, request)
    }

    getMasterCcList(compId) {
        return this._dataService.callRestful(URLS_INFO.FETCH_MASTER_CC_LIST.METHOD, URLS_INFO.FETCH_MASTER_CC_LIST.URL + compId);
    }

    getFlowEnableStatus(req) {
        return this._dataService.callRestful(URLS_INFO.GET_FLOW_ENABLE.METHOD, URLS_INFO.GET_FLOW_ENABLE.URL, req);
    }

    updateMMFlow(request) {
        return this._dataService.callRestful(URLS_INFO.ENABLE_MM_FLOW.METHOD, URLS_INFO.ENABLE_MM_FLOW.URL, request);
    }

    repullPo(request){
        return this._dataService.callRestful(URLS_INFO.REPULL_PO.METHOD,URLS_INFO.REPULL_PO.URL,request);
    }

    repushPo(request){
        return this._dataService.callRestful(URLS_INFO.REPUSH_PO.METHOD,URLS_INFO.REPUSH_PO.URL,request);
    }

    getPoSummaryDetails(params) {
        return this._dataService.callRestful(URLS_INFO.FETCH_PO_SUMMARY.METHOD, URLS_INFO.FETCH_PO_SUMMARY.URL + 'fromDate=' + params.fromDate + '&toDate=' + params.toDate +'&type=SAP')
    }
    auditPoHistory(request){
        return this._dataService.callRestful(URLS_INFO.AUDITPOHISTORY.METHOD,URLS_INFO.AUDITPOHISTORY.URL,request)
    }
    repushNonAbfrlPo(params){
        return this._dataService.callRestful(URLS_INFO.REPUSH_NON_ABFRL_PO.METHOD, URLS_INFO.REPUSH_NON_ABFRL_PO.URL + params.fromDate + '/' + params.toDate)
    }
    getHzlReport(req){
        return this._dataService.callRestful(URLS_INFO.GET_HZL_REPORT.METHOD, URLS_INFO.GET_HZL_REPORT.URL ,req)
    }
    downloadHzlReport(req){
        return this._dataService.callRestful(URLS_INFO.DOWNLOAF_HZL_REPORT.METHOD, URLS_INFO.DOWNLOAF_HZL_REPORT.URL ,req)
    }
}