//import * as _ from 'underscore';

export class PagerService {
    //getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {
  getPager(totalItems: number, currentPage: number, pageSize: number) {
        // calculate total pages
        let totalPages = Math.ceil(totalItems / pageSize);
        // // console.log("totalPages");// console.log(totalPages);

        // if(totalPages > 1000){
        //     totalPages = 1000;
        // }

        let startPage: number, endPage: number;
        if (totalPages <= 5) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (currentPage <= 3) {
                startPage = 1;
                endPage = 5;
            } else if (currentPage + 3 >= totalPages) {
                startPage = totalPages - 4;
                endPage = totalPages;
            } else {
                startPage = currentPage - 2;
                endPage = currentPage + 2;
            }
        }

        // // console.log("startPage");// console.log(startPage);
        // // console.log("endPage");// console.log(endPage);

        // calculate start and end item indexes
        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        //let pages = _.range(startPage, endPage + 1);
        let pages : number[] = [];
        for(let i=startPage;i<endPage+1;i++){
          pages.push(i);
        }

      // // console.log("pages array from the pagerService");// console.log(pages);

        // return object with all pager properties required by the view
          let startItem = ( pageSize*(currentPage-1) )+1;
          let endItem=0;
          if(currentPage > totalItems/pageSize) endItem = ( (currentPage-1)*pageSize )+(totalItems%pageSize);
          else endItem = pageSize*currentPage;
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            startItem: startItem,
            endItem: endItem,
            pages: pages
        };
    }
}
