import {Component, ViewEncapsulation, OnInit, Input, SimpleChanges} from '@angular/core';
import { Router } from '@angular/router';

import { DataService } from '../../../../../pages/data.service';
import { params } from '../../../../../pages/params';
import { urls } from '../../../../../pages/urls';
import {environment} from "../../../../../../environments/environment";

@Component({
  selector: 'ba-user-profile',
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'baUserProfile.html',
})
export class BaUserProfile implements OnInit{
    userName: any;
    userEmail: any;
    userAcronym: any;
    spendLimit: any;
    hasSpendLimit: any = true;
    @Input() branchType = 'normalBuyer';

    constructor(private router: Router, private _dataService: DataService) {
    }

    ngOnInit() {
        this.getUserDetails();
    }
    ngOnChanges(changes: SimpleChanges){
        this.branchType = changes.branchType.currentValue;
    }
    public getUserDetails() {
        this.userName = this._dataService.getUserName();
        const matches = this.userName.match(/\b(\w)/g);
        this.userAcronym = (matches.join('')).slice(0,2);
        this.userEmail = this._dataService.getUserEmail();
    }
    public getSpendLimit() {
        const sReq = {
            'idUser': this._dataService.getUserId(),
            'idBranch': this._dataService.getBuyerPlantId()
        }
        this._dataService.callRestful('POST', environment.URLS.AUTH_URL + urls.SPEND_LIMIT.USER, sReq).subscribe(
            (res) => {
                //console.log('spend data', res);
                const data = res['data'];
                (data !== null) ? (this.spendLimit = (data['spendLimit'] - data['spent']).toFixed(2)) : this.hasSpendLimit = false;
                if (this.spendLimit <= 0) {
                    this.spendLimit = 0;
                }
        });
    }
    public onLogout() {
        this.router.navigateByUrl('/logout');
    }

}
