import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, ActivationEnd, NavigationEnd, Router } from "@angular/router";
import { fromEvent, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { AppSettings, Settings } from '../../../../../app.settings';
import { DataService } from '../../../../../pages/data.service';
import { MmCatalogService } from '../../../../../pages/mmCatalog/mmCatalog.service';
declare var dataLayer: any;


@Component({
  selector: 'app-mm-search',
  templateUrl: './mm-search.component.html',
  styleUrls: ['./mm-search.component.scss']
})
export class MmSearchComponent implements OnInit {
  // MM Search bar
  showMMSearchBar: any = false;
  @ViewChild('MMinput', {static: true}) MMinput: ElementRef;
  public settings: Settings;

  // suggestions Dropdown
  suggestionList:any = {};
  showList = false;

  constructor(
    public _appSettings:AppSettings,
    private _location: Location,
    private _router: Router,
    private _dataService: DataService,
    private _mmCatalogService: MmCatalogService,
    private route: ActivatedRoute
  ) { 
    this.settings = this._appSettings.settings;
  }

  ngOnInit(): void {
    this.checkIsMM();
  }

  checkIsMM(){
    this.route.url.subscribe(() => {
      if(this._location.path().includes("/mm-catalog/")){
        this.showMMSearchBar = true;
        if(!this._location.path().includes("mm-catalog/home-product-list?str")){
          this.settings.mmSearchValue = '';
       }
      }else{
        this.showMMSearchBar = false;
      }
    });
  }

  ngOnChanges(){}

  ngAfterViewInit(){
    fromEvent(this.MMinput.nativeElement,'keyup')
    .pipe(
        filter(Boolean),
        debounceTime(1000),
        distinctUntilChanged(),
        tap((event:KeyboardEvent) => {
          if(event.code == 'Enter'){
            dataLayer.push({'event': 'searchKey', 'searchKey': this.MMinput.nativeElement.value});
            this._router.navigateByUrl(`pages/mm-catalog/home-product-list?str=${this.MMinput.nativeElement.value}`);
          }else{
            this.searchSuggestions();
          }
        })
    )
    .subscribe();
  }

  searchSuggestions(){
    $('#loader').css('display', 'block');
    this.suggestionList = {};
    this.showList = false;
    let req = {str: this.MMinput.nativeElement.value};
    this._mmCatalogService.searchSuggestions(req).subscribe((res) => {
      $('#loader').css('display', 'none');
      this.showList = true;
      if(res && res?.code == 200 && res?.data){
        this.suggestionList = res.data;
      }else{
        this._dataService.showMessage('error', res?.message);
      }
    })
  }

  @HostListener('document:click', ['$event.path'])
    public onGlobalClick(e: any) {
        this.showList = false;
    }

  onClickInput(){
    setTimeout(() => {
      this.showList = true;
    }, 100)
  } 
  
  openLink(term){
    dataLayer.push({'event': 'searchKey', 'searchKey': term});
    this._router.navigateByUrl(`pages/mm-catalog/home-product-list?str=${term}`);
  }

  viewCategory(categoryId){
    this._router.navigateByUrl(`pages/mm-catalog/home-product-list?id=${categoryId}`);
  }

}
