import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import * as Highcharts from 'highcharts';
import { ConvertCurrencyService } from './../../../theme/services/convert-currency.service';
@Component({
  selector: 'app-statusgraph',
  templateUrl: './statusgraph.component.html',
  styleUrls: ['./statusgraph.component.scss']
})
export class StatusgraphComponent implements OnInit {
  @Input() statuses = [];
  @Input() count = [];
  @Input() barColors = [];
  @Output() emitSelectedStatus: EventEmitter<any> = new EventEmitter();

  constructor(private _convertCurrencyService: ConvertCurrencyService) { }

  ngOnChanges(changes: SimpleChanges) {
    //console.log('count', this.count, this.statuses);
    this.statusgraphchart();
  }

  getMaxCount() {
    let max: any = 0;
    for(let i = 0; i < this.count.length; i++) {
      if (this.count[i] > max) {
        max = this.count[i];
      }
    }
    let noOfDigits: number = max.toString().length;
    let divisor = 1;
    for (let i = 1; i < noOfDigits; i++) {
      divisor = divisor * 10;
    }
    let firstDigit = Math.floor(max / divisor);
    max = (firstDigit + 1) * divisor;
    return max;
  }

  statusgraphchart(){

    let chartData = [];

    this.count.forEach((item, index) => {
      chartData.push({
        y: item, //this._convertCurrencyService.convertCurrency(item),
        color: this.barColors[index] // use color config
      })
    })
    let newThis = this;
    const pochart =  Highcharts.chart('statusgraphcontainer',  Highcharts.merge(<any>{
      chart: {
          type: 'column',
          style: {
            fontFamily: "'Poppins', sans-serif ",
          }
      },
      title: {
          text: null
      },
      xAxis: {
          categories: this.statuses,
          labels: {
            style: {
               color: '#111',
               fontSize:'10px'
            }
          }
      },
      yAxis: {
          min: 0,
          //max: this.getMaxCount(),
          endOnTick: false,
          title: {
              text: null
          },
          stackLabels: {
              enabled: false,
          },
          labels: {
            style: {
               color: '#111',
            },
          //   formatter: function () {
          //     return this.axis.defaultLabelFormatter.call(this) + ' k';
          // }
         },
      },
      legend: {
          enabled: false
      },
      credits: {
        enabled: false
      },
      tooltip: {
          headerFormat: '<b>{point.x}: </b>',
          pointFormat: '{point.y}'
      },
      plotOptions: {
          column: {
              stacking: 'normal',
              point: {
                events: {
                  click : (e) => {
                    this.emitSelectedStatus.emit(e.point.category)
                  }
                },
              },
          },
          series: {
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              formatter: function() {
                return newThis._convertCurrencyService.convertCurrency(this.y);
              }
            }
          }
      },
      series: [
        {
          colorByPoint: true,
          data: chartData
        }
    ]
  }));
  }

  ngOnInit() {
    //this.statusgraphchart();

  }

}
