/**
 * Created by akul on 16/1/17.
 */
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from "@angular/router";
import {Observable} from "rxjs";
import { Inject, Injectable } from "@angular/core";
import {DataService} from "./data.service";
import {urls} from "./urls";
import { Location } from '@angular/common';
import {BaThemePreloader} from "../theme/services/baThemePreloader/baThemePreloader.service";
import {BaThemeSpinner} from "../theme/services/baThemeSpinner/baThemeSpinner.service";
import {environment} from "../../environments/environment";
import { CommonapiService } from "./commonapi.service";

@Injectable()
export class AuthGuard implements CanActivate {
  path:string;
  constructor(@Inject(DataService) public  _dataService: DataService, @Inject(Router)public router:Router,@Inject(BaThemeSpinner)public _spinner: BaThemeSpinner, @Inject(Location)public _location: Location, private _commonapiService: CommonapiService){ }

  canActivate(route:ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean{
    // console.log("inside auth guard");
    let result = this._dataService.isAuthenticated();
    this.path = route.routeConfig.path;
    // console.log("Result is: "+result);
    if(!result){
      let userId = this._dataService.getCookie('moglixB2BUserId');
      let token = this._dataService.getCookie('moglixB2BToken');
      if(!token || token == 'undefined' || token.length <= 0) {
        BaThemePreloader.load().then((values) => {
          this._spinner.hide();
        });
        if (this._location.path() !== '/login' && this._location.path() !== '/logout') {
          const redirectUrl = state.url === '/login' ? '/' : state.url;
          this.router.navigate(['login'], {queryParams: {redirectUrl}});
        }
        return result;
      }
      else {
        // console.log("Fetching data");
        let reqObj = {
          "dataType" : 1
        } ;
        return this.isLoggedOnServer(reqObj);
      }
    }else{
      return result;
    }
  }

  isLoggedOnServer(reqObj:any):Observable<boolean>{
    return new Observable<boolean>( observer => {
        this._dataService.callRestful('POST', environment.URLS.AUTH_URL+urls.AUTH.GET_SESSION, reqObj).subscribe(
          (data) => {
            if (data['successful']) {
              // this._dataService.setSessionObj(data.data);
              reqObj['dataType'] = 2;
              this._dataService.callRestful('POST', environment.URLS.AUTH_URL+urls.AUTH.GET_SESSION, reqObj).subscribe(
                  (sessionData) => {
                    if (sessionData['successful'] && sessionData['data']['companyData'] && Object.keys(sessionData['data']['companyData']['branchNames']).length > 0 ) {
                      let accessReqObj = {
                        "idUser": data['data']['userData']['userId'],
                        "idBranch": this._dataService.getCookie('plantId') === '-1' ? null : this._dataService.getCookie('plantId'),
                      };
                      let _sessionObj = Object.assign({},data['data']['userData'],sessionData['data']['companyData'], {});
                      this._dataService.setSessionObj(_sessionObj);
                      if (this._dataService.getCookie('plantId') === '-1') {
                        accessReqObj['plantIds'] = this._dataService.getAllPlantsByCurrCompany(this._dataService.getCookie('companyId'));
                      }
                      this._dataService.callRestful('POST', environment.URLS.AUTH_URL + this._commonapiService.getUrls().UM.AUTH.GET_BRANCH_ACCESS, accessReqObj).subscribe(
                          (accessData) => {
                            if(accessData['successful']){
                              BaThemePreloader.load().then((values) => {
                                this._spinner.hide();
                              });
                              let _sessionObj = Object.assign({},data['data']['userData'],sessionData['data']['companyData'],accessData['data']['branchModules']);
                              this._dataService.setSessionObj(_sessionObj);
                              // console.log(_sessionObj);
                              // console.log("PATH..........",this.path);
                              observer.next(true);
                              observer.complete();
                              // this.router.navigateByUrl('/pages');
                              // console.log("done");
                            }
                            else{
                              BaThemePreloader.load().then((values) => {
                                this._spinner.hide();
                              });
                              observer.next(false);
                              observer.complete();
                              this.logError(accessData);
                              this.router.navigateByUrl('/login');
                            }
                          }
                      );
                    }
                    else{
                      BaThemePreloader.load().then((values) => {
                        this._spinner.hide();
                      });
                      observer.next(false);
                      observer.complete();
                      this.logError(sessionData);
                      this.router.navigateByUrl('/login');
                    }
                  }
                  );
            }
            else {
              BaThemePreloader.load().then((values) => {
                this._spinner.hide();
              });
              observer.next(false);
              observer.complete();
              this.logError(data);
              this.router.navigateByUrl('/login');
            }
          }
        );
      }
    );
  }
  logError(data){
    this._dataService.showMessage('error',data['message']);
    this._dataService.deleteCookie('moglixB2BUserId');
    this._dataService.deleteCookie('moglixB2BToken');
    this._dataService.deleteCookie('plantId');
  }
}
