import { Subscription } from 'rxjs';
import { Component, ViewChild, HostListener, Input, ElementRef, OnInit, OnDestroy, AfterViewInit, AfterViewChecked } from '@angular/core';
import { DataService } from '../../../pages/data.service';
import { environment } from '../../../../environments/environment';
import * as _ from 'lodash';
import * as moment from "moment";

@Component({
  selector: 'messagesupport',
  styleUrls:['messagesupport.scss'],
  templateUrl: './messagesupport.html',
})
export class MessageSupport implements OnInit, OnDestroy, AfterViewChecked{
  openchat:boolean = false;
  plantId: any;
  companyId: any;
  query: any = '';
  comment: any = '';
  chatId: any = '';
  noQuery = true;
  chatDataPresent = false;
  chatData: any[] = [];
  anslength: any = 0;
  ansType: any;
  commentBox: any = false;
  selectedChatId: any;
  upsertReq: any = {};
  customerReply: any = false;
  branchNames: any;
  currUser: any;
  moglixSupport = false;
  disableScrollDown = false;

  // subscription variables
  plantSubscription: Subscription;
  // API variables
  URLS: any;
  @ViewChild('scrollMe', { static: true }) private myScrollContainer: ElementRef;

  constructor(private _dataService: DataService) {}

  ngOnInit() {
    this.URLS = {
      UPSERT_CHAT: { METHOD: 'POST', URL: environment.URLS.EOC_API + 'chat/upsertAndMailToUser' },
      // GET_CHAT: { METHOD: 'POST', URL: environment.URLS.EOC_API + 'chat/getAllChatDetailsOfBranch' }
    }

    this.companyId = this._dataService.getCurrCompanyID().toString();
    this.plantId = this._dataService.getBuyerPlantId();
    this.branchNames = this._dataService.getSessionObj()['branchNames'];
    this.currUser = this._dataService.getUserEmail();
    this.moglixSupport = this.currUser === 'moglix.team@gmail.com' ? true : false;

    // this.getAllMessages();
    this.initialiseSubscribers();
    this.scrollToBottom();

  }

  ngAfterViewChecked() {
      this.scrollToBottom();
  }

  scrollToBottom(): void {
    if (this.disableScrollDown) {
      return;
    }
    try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch(err) { }
  }

  onScroll() {
    let element = this.myScrollContainer.nativeElement;
    let atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;
    if (this.disableScrollDown && atBottom) {
        this.disableScrollDown = false;
    } else {
        this.disableScrollDown = true;
    }
  }

  initialiseSubscribers() {
    this.plantSubscription = this._dataService.pSubject.subscribe((value) => {
      this.plantId = value;
      this.chatData = [];
      this.noQuery = true;
      this.commentBox = false;
      this.selectedChatId = '';
      // this.getAllMessages();
    });
  }

  getAllMessages() {
    const request = {
      'idBranch': this.plantId
    };
    this._dataService.callRestful(this.URLS.GET_CHAT.METHOD, this.URLS.GET_CHAT.URL, request).subscribe(
      (data) => {
        //console.log('chat data', data, data.data.data);
        if(data.code === 200) {
          let length = data.data.data.length;
          if(length === 0) {
            this.chatDataPresent = false;
          } else {
            this.chatDataPresent = true;
            this.makeChatData(data.data.data);
          }
        }
      }
    )
  }

  makeChatData(data: any) {
    let _chatdata: any[] = [];
    for(let i = 0; i < data.length; i++) {
      let _chatItem: any = {};
      if(data[i].question) {
        _chatItem = {
          question: data[i].question.chat,
          creatorName: data[i].question.userName,
          creatorId: data[i].question.userMailId,
          creationDate: moment(data[i].question.creationDate).format('DD/MM/YYYY'),
          creationTime: moment(data[i].question.creationDate).format('hh:mm a'),
        };
        const matches = _chatItem.creatorName.match(/\b(\w)/g);
        _chatItem['creatorAcronym'] = (matches.join('')).slice(0, 2);
      }
      _chatItem['id'] = data[i]._id;
      if (data[i].reply) {
        _chatItem['replyArray'] = data[i].reply;
        this.anslength = data[i].reply.length;
      }
      if(this.moglixSupport) {
        this.customerReply = false;
      } else {
        this.customerReply = true;
      }
      _chatdata.push(_chatItem);
    }
    this.chatData = _.cloneDeep(_chatdata);
    //console.log('chat data', this.chatData);
  }

  openCommentBox(event, data) {
    this.comment = '';
    event.preventDefault();
    this.commentBox = true;
    this.selectedChatId = data.id;
    //this.disableScrollDown = true;
    //this.onScroll();

  }

  upsertQuery(type?: any) {
    this.reduceQueryBox();
    if (!type && !this.query.replace(/\s/g, '').length) {
      return;
    } else if(type && !this.comment.replace(/\s/g, '').length){
      return;
    }
    this.upsertReq['plantId'] = this.plantId,
    this.upsertReq['plantName'] = this.branchNames[this.plantId],
    this.upsertReq['userId'] = this._dataService.getUserId(),
    this.upsertReq['message'] = type && type === 'comment' ? this.comment : this.query;
    this._dataService.callRestful(this.URLS.UPSERT_CHAT.METHOD, this.URLS.UPSERT_CHAT.URL, this.upsertReq).subscribe(
      (data) => {
        if (data.code === 200) {
          this.query = '';
          this.comment = '';
          this.noQuery = true;
          this.selectedChatId = '';
          delete this.upsertReq['chatId'];
          this.getAllMessages();
        }
        //console.log('upsert', data);
      }
    );
  }

  submitReply(data) {
    if (!this.comment.replace(/\s/g, '').length) {
      return;
    }
    this.upsertReq['chatId'] = data.id;
    this.commentBox = false;
    this.upsertQuery('comment');
  }

  toggleQuery() {
    this.noQuery = false;
  }

  enlargeQueryBox() {
    $('#queryBox').removeClass('queryformbox');
    $('#queryBox').addClass('anyquery');
    $('.textarea').parent('#queryBox').siblings('.chatarea').addClass('reduceheight')
    $('#bottomBtn').css('display', 'block');
  }

  reduceQueryBox() {
    $('#queryBox').addClass('queryformbox');
    $('#queryBox').removeClass('anyquery');
    $('.textarea').parent('#queryBox').siblings('.chatarea').removeClass('reduceheight');
    $('#bottomBtn').css('display', 'none');
    this.disableScrollDown = false;
    this.scrollToBottom();
  }

  togglechatbox() {
    this.openchat = true;
  }
  closechatbox() {
    this.openchat = false;
  }

  ngOnDestroy() {
    this.plantSubscription = null;
  }

  // @Input() position:number = 400;
  // @Input() showSpeed:number = 500;
  // @Input() moveSpeed:number = 1000;

  // @ViewChild('baBackTop', { static: true }) private _selector:ElementRef;

  // ngAfterViewInit () {
  //   this._onWindowScroll();
  // }

  // @HostListener('click')
  // _onClick():boolean {
  //   jQuery('html, body').animate({scrollTop:0}, {duration:this.moveSpeed});
  //   return false;
  // }

  // @HostListener('window:scroll')
  // _onWindowScroll():void {
  //   let el = this._selector.nativeElement;
  //   window.scrollY > this.position ? jQuery(el).fadeIn(this.showSpeed) : jQuery(el).fadeOut(this.showSpeed);
  // }
}
